import { GenericService } from 'src/app/services/generic.service';
import { LoaderService } from 'src/app/services/loading-service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { AlertService } from '../../services/alert.service';
import { NavParams, PopoverController } from '@ionic/angular';
import { EventService } from '../../services/event.service';
import { Router } from '@angular/router';
import { LocalStorageEncryptService } from '../../services/local-storage-encrypt.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { environment, idEmpresa } from 'src/environments/environment.prod';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-opciones-reporte',
  templateUrl: './opciones_reporte.component.html',
  styleUrls: ['./opciones_reporte.component.scss'],
})
export class OpcionesReporteComponent implements OnInit {

  public tipoReporte: any[] = [];
  public user: any = null;
  public users: any = [];
  public reunion: any = null;

  constructor(
    private localStorageEncryptService: LocalStorageEncryptService,
    private router: Router,
    private popoverController: PopoverController,
    private alertService: AlertService,
    private sqlGenericService: SqlGenericService,
    private loadingService: LoaderService,
    private navParams: NavParams,
    public genericService: GenericService,
    private translateService: TranslateService
  ) {
    this.tipoReporte = this.navParams.get("tipoReporte");
    this.users = this.navParams.get("users");
    //console.log(this.users);

    this.reunion = this.navParams.get("reunion");
    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
  }

  ngOnInit() {
    //this.getOptions();
    //console.log(this.reunion);

  }

  search(tipo) {
    //console.log(this.reunion);
    //console.log(tipo);

    this.alertService.customizeAlert({
      type: null,
      title: null,
      text: null,
      showCancelButton: false,
      confirmButtonText: this.translateService.instant("accept"),
      showCloseButton: false,
      allowOutsideClick: false,
      customClass: "alerta-vista",
      html: `<section>
        <p>${this.translateService.instant("report.reason")}</p>

        <select name="idioma" id="user" style="background-color: #fff;
        border: 1px solid #cacaca;
        border-radius: 4px;
        width: 100%;
        padding: 10px;" disabled="true">
          ${Object.keys(this.users).map((key) => {
        return "<option value='" + this.users[key].id + "'>" + (this.users[key].nombre + " " + this.users[key].apellidos) + "</option>"
      }).join("")}
        </select>

        <br>
        <br>

        <textarea id="comentario-adicional" placeholder="${this.translateService.instant("report.aditional")}" style="padding:10px;border-radius:4px;border: 1px solid #cacaca;
        outline:none;background-color: #FFF;width: 100%;" rows="4" cols="50"></textarea>
      </section>`
    }, () => {
      //this.inputs[5].valuesMultiple = event;
      let comentarioAdicinal = (<any>document.getElementById("comentario-adicional")).value;
      //if (comentarioAdicinal.length > 0) {
        let usSelect = (<any>document.getElementById("user")).value;

        let sqlReporte: string = `INSERT INTO comentarios (id_usuario, id_usuario_reportado, id_tipo_comentario, descripcion) VALUES (${this.user.id}, ${usSelect}, ${tipo.value}, '${comentarioAdicinal}')`;
        this.loadingService.show();
        this.sqlGenericService.excecuteQueryStringReference(sqlReporte, "userReport").subscribe(async (response: any) => {
          let usSelectComplete: any = {};
          this.users.forEach((us: any) => {
            if (us.id == usSelect) {
              usSelectComplete = us;
            }
          });

          this.popoverController.dismiss();
          this.sendCorreo(usSelectComplete, tipo, comentarioAdicinal);

        }, (error: HttpErrorResponse) => {
          this.loadingService.hide();
          //no notificar error
        });
      
    });

  }

  sendCorreo(usSelectComplete: any, tipoReporte: any, comentario: any) {

    let request: any = {
      asunto: `${this.translateService.instant("report.attach-from")}: ${usSelectComplete.nombre} ${usSelectComplete.apellidos}`,
      from: "connectandem@institutofranklin.net",
      name: this.user.nombre,
      to: "connectandem@institutofranklin.net",
      cuerpo: `<section>
    <div style="background-color: #006b89;
    text-align: center;padding: 8px;">
      <p style="color: #fff;margin: 0;font-size: 20px;">${this.translateService.instant("university.automatic")}</p>
    </div>
    <div style="padding: 10px;border: 1px solid #c8c8c8;">
      <p style="color: #000;">${this.translateService.instant("report.from")} ${this.user.nombre} ${this.user.apellidos}.</p>
      
      <p>${this.translateService.instant("report.attach")}: <strong>${tipoReporte.label}</strong></p>
      <br><br>
      <p>${this.translateService.instant("report.comments")}: <strong>${comentario}</strong></p>
    </div>
  </section>`
    };

    this.genericService.sendPostRequest(environment.mail, request).subscribe((response: any) => {
      this.loadingService.hide();
      //window.history.back();
      this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("report.notification"));
      //this.router.navigate(["login"]);
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      this.alertService.errorAlert("Oops!", this.translateService.instant("alerts.error"));
    });
  }
}
