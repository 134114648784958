import { EventService } from './../../services/event.service';
import { Subscription } from 'rxjs';
import { LoaderService } from './../../services/loading-service';
import { HttpErrorResponse } from '@angular/common/http';
import { SqlGenericService } from './../../services/sqlGenericService';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit, OnDestroy {

  public user: any = null;
  public photo: string = "assets/imgs/perfil/profile.png";

  public localizacion: string = "";
  public sus: Subscription = null;
  constructor(
    public themeService: ThemeService,
    private localStorageEncryptService: LocalStorageEncryptService,
    public router: Router,
    private sqlGenericService: SqlGenericService,
    private loadingService: LoaderService,
    private eventService: EventService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
    this.user.disponibilidad_json2 = JSON.parse(this.user.disponibilidad_json);
    console.log(this.user.disponibilidad_json2);

  }

  ngOnInit() {
    this.sus = this.eventService.get("updateProfile").subscribe((data) => {
      this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
      console.log(this.user);
      this.user.disponibilidad_json2 = JSON.parse(this.user.disponibilidad_json);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.sus) {
      this.sus.unsubscribe();
    }
  }

  goToEditProfile() {
    this.router.navigateByUrl('/edit-profile')
  }

  changeCheck(value) {
    //console.log(value);
    //console.log(this.user.disponibilidad_json2.dias.L);

  }

  onChange(hour) {
    //console.log(this.user.disponibilidad_json2.horas.inicio);


  }

  save() {
    console.log(this.user.disponibilidad_json2);
    let sql: string = `UPDATE usuario SET disponibilidad_json = '${JSON.stringify(this.user.disponibilidad_json2)}' WHERE id = ${this.user.id}`;
    this.loadingService.show();
    this.sqlGenericService.excecuteQueryStringReference(sql, "updateDisponibilidad").subscribe((r: any) => {
      this.user.disponibilidad_json = JSON.stringify(this.user.disponibilidad_json2);
      this.localStorageEncryptService.setToLocalStorage("userSessionConnectandem", this.user);
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }
}
