import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "./../../../../../services/theme.service";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { OpenVidu, Publisher } from "openvidu-browser";
import { UserModel } from "../../models/user-model";
import { OpenViduService } from "../../services/openvidu.service";

declare var cordova;

@Component({
  selector: "app-setting-up-modal",
  templateUrl: "./setting-up-modal.component.html",
  styleUrls: ["./setting-up-modal.component.scss"],
})
export class SettingUpModalComponent implements OnInit {
  OV: OpenVidu;
  localUser: UserModel;
  audioDevice: any;
  audioDevices: any[] = [];
  videoDevices: any[] = [];
  speakerphone = false;

  public publisher;

  @Input() room = "";

  public audioOptions: any = [
    {
      value: "None",
      label: "Disabled",
    },
  ];

  public video2: any = "None";
  public audio2: any = "None";

  constructor(
    public modalController: ModalController,
    public platform: Platform,
    private openViduSrv: OpenViduService,
    public themeService: ThemeService,
    private translateService: TranslateService
  ) {}

  close() {
    this.dismiss();
  }

  ngOnInit() {
    console.log(<any>window);

    this.platform.ready().then(() => {
      this.OV = new OpenVidu();
      this.localUser = new UserModel();
      if (this.platform.is("cordova") || this.platform.is("android")) {
        this.openViduSrv
          .checkAndroidPermissions()
          .then(() => {
            navigator.mediaDevices.ondevicechange = (ev) => {
              this.initDevices();
            };
            this.initPublisher()
              .then(() => this.initDevices())
              .catch((error) => {});
          })
          .catch((err) => {
            //console.log(err);
            this.dismiss();
          });
      } else {
        this.initPublisher()
          .then(() => this.initDevices())
          .catch((error) => {});
      }
    });
  }

  async initDevices() {
    const devices2 = await this.OV.getDevices();
    console.log("previusLy");

    console.log(devices2);

    // const device2[] = devices2.find(device => device.label === 'Built-in Audio Analog Stereo');
    this.OV.getUserMedia({
      audioSource: devices2[1].deviceId,
      videoSource: devices2[devices2.length - 1].deviceId,
    }).then(
      (resp: any) => {
        console.log(resp);
        console.log("ACCESS SUCCESS----------------");
        this.OV.getDevices().then((devices: any) => {
          this.audioDevices = devices.filter(
            (device) => device.kind === "audioinput"
          );
          console.log(this.audioDevices);
          this.audioDevices.forEach((element, index) => {
            switch (index) {
              case 0:
                element.label =
                  this.translateService.instant("meet.microphone");
                break;
              case this.audioDevices.length - 1:
                element.label = this.translateService.instant("meet.speaker");
                break;
              default:
                element.label = element.kind;
                break;
            }
          });
          this.videoDevices = devices.filter(
            (device) => device.kind === "videoinput"
          );
          this.videoDevices.forEach((element, index) => {
            switch (index) {
              case 0:
                element.label = this.translateService.instant("meet.frontal");
                break;
              case this.videoDevices.length - 1:
                element.label = this.translateService.instant("meet.rear");
                break;
              default:
                element.label = element.kind;
                break;
            }
          });
          this.video2 = this.videoDevices[0];
          this.audio2 = this.audioDevices[0];

          if (this.platform.is("ios")) {
            //console.log('iOS platform');
            setTimeout(() => {
              this.refreshVideos();
            }, 1100);
          } else if (this.platform.is("android")) {
            //console.log('Android platform');
          }
          this.localUser.setVideoSource(this.videoDevices[0].deviceId);
          // }
        });
      },
      (error: any) => {
        console.log("ACCESS DENIED----------------");

        console.log(error);
        switch (error.name) {
          case "DEVICE_ACCESS_DENIED":
            this.OV.getDevices().then((devices: any) => {
              this.audioDevices = devices.filter(
                (device) => device.kind === "audioinput"
              );
              console.log(this.audioDevices);
              this.audioDevices.forEach((element, index) => {
                switch (index) {
                  case 0:
                    element.label =
                      this.translateService.instant("meet.microphone");
                    break;
                  case this.audioDevices.length - 1:
                    element.label =
                      this.translateService.instant("meet.speaker");
                    break;
                  default:
                    element.label = element.kind;
                    break;
                }
              });
              this.videoDevices = devices.filter(
                (device) => device.kind === "videoinput"
              );
              this.videoDevices.forEach((element, index) => {
                switch (index) {
                  case 0:
                    element.label =
                      this.translateService.instant("meet.frontal");
                    break;
                  case this.videoDevices.length - 1:
                    element.label = this.translateService.instant("meet.rear");
                    break;
                  default:
                    element.label = element.kind;
                    break;
                }
              });
              console.log(this.videoDevices);
              this.video2 = this.videoDevices[0];
              this.audio2 = this.audioDevices[0];
              //if (this.platform.is('cordova')) {
              if (this.platform.is("ios")) {
                //console.log('iOS platform');
                setTimeout(() => {
                  this.refreshVideos();
                }, 1100);
              } else if (this.platform.is("android")) {
                //console.log('Android platform');
              }
              this.localUser.setVideoSource(this.videoDevices[0].deviceId);
              //}
            });
            break;

          default:
            console.log(error);
            break;
        }
      }
    );
  }

  dismiss() {
    this.modalController.dismiss();
    this.destroyPublisher();
    this.localUser = null;
    //this.router.navigate(["reuniones"]);
  }

  setAudioDevice2(event) {
    this.audioDevice = this.audio2;
    const audioSource =
      this.audioDevice.deviceId === "None"
        ? undefined
        : this.audioDevice.deviceId;
    this.localUser.setAudioActive(!!audioSource);
    this.localUser.setAudioSource(audioSource);
    if (!!audioSource) {
      this.destroyPublisher();
      this.initPublisher();
    } else {
      this.micOff();
    }
  }

  setAudioDevice(event) {
    //console.log('Setting audio device to: ', event.detail.value.label);
    this.audioDevice = event.detail.value;
    const audioSource =
      this.audioDevice.deviceId === "None"
        ? undefined
        : this.audioDevice.deviceId;
    this.localUser.setAudioActive(!!audioSource);
    this.localUser.setAudioSource(audioSource);
    if (!!audioSource) {
      this.destroyPublisher();
      this.initPublisher();
    } else {
      this.micOff();
    }
  }

  setVideoDevice2(event) {
    console.log(this.video2);

    console.log("Setting video device to: ", event);
    const videoSource =
      this.video2.deviceId === "None" ? undefined : this.video2.deviceId;
    this.localUser.setVideoActive(!!videoSource);
    this.localUser.setVideoSource(videoSource);
    if (!!videoSource) {
      this.destroyPublisher();
      this.initPublisher();
    } else {
      this.camOff();
    }
  }

  setVideoDevice(event) {
    console.log("Setting video device to: ", event.detail.value.label);
    const videoSource =
      event.detail.value.deviceId === "None"
        ? undefined
        : event.detail.value.deviceId;
    this.localUser.setVideoActive(!!videoSource);
    this.localUser.setVideoSource(videoSource);
    if (!!videoSource) {
      this.destroyPublisher();
      this.initPublisher();
    } else {
      this.camOff();
    }
  }

  camOff() {
    (<Publisher>this.localUser.getStreamManager()).publishVideo(false);
  }

  micOff() {
    (<Publisher>this.localUser.getStreamManager()).publishAudio(false);
  }

  refreshVideos() {
    console.log("prueba de update");
    if (this.platform.is("ios") && this.platform.is("cordova")) {
      let w:any = window;
      let plugins:any = w.cordova.plugins;
      //plugins.iosrtc.capacitor.registerGlobals();
      plugins.iosrtc.capacitor.refreshVideos();
      //cordova.plugins.iosrtc.capacitor.refreshVideos();
    }
  }

  join() {
    this.modalController.dismiss({
      user: this.localUser,
      videoDevices: this.videoDevices,
    });
  }

  private initPublisher(): Promise<any> {
    return new Promise((resolve, reject) => {
      //console.log('initialize publisher');
      const device = this.videoDevices.filter(
        (video) => video.deviceId === this.localUser.getVideoSource()
      );

      let isBackCamera = false;
      if (this.platform.is("capacitor")) {
        isBackCamera = !!device[0] && device[0].label.includes("Back");
      }
      this.localUser.setIsBackCamera(isBackCamera);

      let prom: any = this.platform.is("ios")
        ? this.OV.initPublisherAsync(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: undefined, // The source of video. If undefined default webcam
            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: true, // Whether you want to start publishing with your video enabled or not
            resolution: "640x480", // The resolution of your video
            frameRate: 30, // The frame rate of your video
            insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
            mirror: true, // Whether to mirror your local video or not
          })
        : this.OV.initPublisherAsync(undefined, {
            audioSource: this.localUser.getAudioSource(),
            videoSource: this.localUser.getVideoSource(),
            publishAudio: this.localUser.isAudioActive(),
            publishVideo: this.localUser.isVideoActive(),
            mirror: !this.localUser.isBackCamera(),
          });
      console.log("promprom");

      console.log(prom);

      prom
        .then((publisher: Publisher) => {
          this.publisher = publisher;
          this.localUser.setStreamManager(publisher);
          resolve(publisher);
        })
        .catch((error) => reject(error));
    });
  }

  private destroyPublisher() {
    //console.log('Destroying publisher...');
    if (
      this.localUser.getStreamManager() &&
      this.localUser.getStreamManager().stream
    ) {
      this.localUser.getStreamManager().stream.disposeWebRtcPeer();
      this.localUser.getStreamManager().stream.disposeMediaStream();
      this.localUser.setStreamManager(null);
    }
  }

  whenClose() {}
}
