import { EventService } from 'src/app/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/services/generic.service';
import { LoaderService } from 'src/app/services/loading-service';
import { UtilService } from 'src/app/services/util.service';
import { AlertService } from 'src/app/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { ThemeService } from 'src/app/services/theme.service';
import { environment, idEmpresa } from 'src/environments/environment.prod';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.page.html',
  styleUrls: ['./edit-profile.page.scss'],
})
export class EditProfilePage implements OnInit, OnDestroy {
  inputs: any[] = [];
  user: any;
  public b64Image: any = "";

  public rendered: boolean = false;

  formControl = new FormControl();
  autoFilter: Observable<string[]>;
  Items: string[] = ['BoJack Horseman', 'Stranger Things', 'Ozark', 'Big Mouth'];

  public niveles: any[] = [];
  public susChangeLanguageDetector: Subscription = null;

  public idUni: any = 1;
  public idCountry: any = 1;
  constructor(
    private sqlGenericService: SqlGenericService,
    public themeService: ThemeService,
    private actionSheetController: ActionSheetController,
    private alertService: AlertService,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoaderService,
    private genericService: GenericService,
    private router: Router,
    private translateService: TranslateService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private eventService: EventService,
    private camerar: Camera
  ) { }

  ngOnDestroy() {
    if (this.susChangeLanguageDetector) {
      this.susChangeLanguageDetector.unsubscribe();
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userSessionConnectandem'));
    console.log(this.user);

    this.loadingService.show();
    this.buildInputs();
    this.getCounties();
    this.getUniversities();

    this.getNiveles();

    this.susChangeLanguageDetector = this.eventService.get("changeLanguage").subscribe((r: any) => {
      this.getCounties();
      this.getLanguages();
    });
  }

  changeSelection(event: any) {
    //console.log(event);
    this.inputs[5].valuesMultiple = [...event];
    //this.tmp1 = event;
  }

  changeSelectionExt(event: any) {
    //console.log(event);
    this.inputs[6].valuesMultiple = [...event];
  }

  test() {
    console.log("edit");

    let errores: number = 0;
    this.inputs.forEach(element => {
      if (!element.valuesMultiple && (element.value.length <= 0 || element.value == 0)) {
        console.log(element);

        errores++;
        element.error = true;
      } else if (element.valuesMultiple && element.valuesMultiple.length <= 0) {
        console.log(element);
        errores++;
        element.error = true;
      } else {
        element.error = false;
      }
    });

    if (errores <= 0) {
      this.loadingService.show();
      let sqlQuery = `UPDATE usuario SET nombre = '${this.inputs[0].value}' ,apellidos = '${this.inputs[1].value}', id_pais = ${this.inputs[2].realValue != 0 ? this.inputs[2].realValue : this.idCountry}, id_universidad = ${this.inputs[3].realValue != 0 ? this.inputs[3].realValue : this.idUni}, otros_idiomas_nativos = '${JSON.stringify(this.inputs[5].valuesMultiple)}', otros_idiomas_extranjeros = '${JSON.stringify(this.inputs[6].valuesMultiple)}', informacion = '${this.inputs[7].value}', titulacion = '${this.inputs[4].value}' WHERE id = ${this.user.id}`;
      console.log(sqlQuery);

      this.sqlGenericService.excecuteQueryString(sqlQuery).subscribe((response: any) => {
        //console.log(response);
        let sql: string = `SELECT * FROM usuario WHERE id = ${this.user.id}`;
        this.sqlGenericService.excecuteQueryString(sql).subscribe((response3: any) => {
          //console.log(response3);
          //this.localStorageEncryptService.setToLocalStorage("userSessionConnectandem", response3.parameters[0]);
          let foto: any = this.user.url_foto;
          //console.log(this.b64Image);

          if (this.b64Image && this.b64Image.length > 0) {
            let requestFile: any = {
              b64: this.b64Image,
              id: this.user.id,//usuario de nueva creacion
              extension: "png",
              table: "usuario"
            };

            this.genericService.sendPostRequest(environment.loadFileOnly, requestFile).subscribe((resp2: any) => {
              foto = this.b64Image;
              this.user = response3.parameters[0];
              this.user.url_foto = foto;
              this.changeDetectorRef.detectChanges();
              //console.log(this.user);

              this.localStorageEncryptService.setToLocalStorage("userSessionConnectandem", this.user);
              this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("alerts.update-profile"));
              this.loadingService.hide();

              this.eventService.send("updateProfile", null);
            }, (err: HttpErrorResponse) => {
            });
          } else {
            this.user = response3.parameters[0];
            this.user.url_foto = foto;
            //console.log();

            this.localStorageEncryptService.setToLocalStorage("userSessionConnectandem", this.user);
            this.eventService.send("updateProfile", null);
            this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("alerts.update-profile"));
            this.loadingService.hide();
          }


        }, (error: HttpErrorResponse) => {
          this.loadingService.hide();
          this.alertService.errorAlert("Oops", this.translateService.instant("alerts.update-profile-error"));
        });


      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.alertService.errorAlert("Oops", this.translateService.instant("alerts.update-profile-error"));
      });
    }
  }

  buildInputs() {
    // 0
    this.inputs.push({
      placeholder: "university.name",
      value: this.user?.nombre, error: false,
      type: "text",
      length: 300
    });
    // 1
    this.inputs.push({
      placeholder: "university.last-name",
      value: this.user?.apellidos, error: false,
      type: "text",
      length: 300
    });
    // 3
    this.inputs.push({
      placeholder: "university.country",
      value: null,
      type: "select",
      realValue: this.user?.id_pais,
      values: [

      ],
      //autocomplete: true
    });
    // 4
    this.inputs.push({
      placeholder: "university.university",
      value: null,
      type: "select",
      realValue: this.user?.id_universidad,
      values: [

      ],
    });
    // 5
    this.inputs.push({
      placeholder: "university.title",
      value: this.user?.titulacion, error: false,
      type: "text",
      length: 300
    });
    // 6
    this.inputs.push({
      placeholder: "university.native-language",
      value: 0,
      type: "select",
      values: [

      ],
      valuesMultiple: JSON.parse(this.user?.otros_idiomas_nativos),
      showSelection: true
    });
    // 7
    this.inputs.push({
      placeholder: "university.foreign-language",
      value: 0,
      type: "select",
      values: [

      ],
      valuesMultiple: JSON.parse(this.user?.otros_idiomas_extranjeros),
      ext: true,
      showSelection: true
    });
    // 8
    this.inputs.push({
      placeholder: "university.about-self",
      value: this.user?.informacion,
      error: false,
      type: "textarea",
      length: 500
    });

    this.getLanguages();
  }

  getCounties() {
    let sqlCountries: string = `SELECT id as value, nombre as label, name_english FROM paises`;
    this.loadingService.show();
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getCountries").subscribe((response: any) => {
      this.inputs[2].values = [...response.parameters];

      console.log(this.genericService.getLocale());

      if (this.genericService.getLocale() == "en") {
        this.inputs[2].values.forEach(element => {
          element.label = element.name_english;
        });
      }

      this.inputs[2].values.unshift({
        label: this.translateService.instant("university.country"),
        value: 0
      });
      let i: number = this.inputs[2].values.findIndex((u: any) => {
        return u.value == this.inputs[2].realValue;
      });
      this.idCountry = Number(this.inputs[2].values[i].value);
      this.inputs[2].value = this.inputs[2].values[i].label;
      this.inputs[2].realValue = this.idCountry;
      console.log(this.inputs);
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      //no notificar error
    });
  }


  getUniversities() {
    this.loadingService.show();
    let sqlCountries: string = `SELECT id as value, descripcion as label FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 45`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getUniversities").subscribe((response: any) => {
      this.inputs[3].values = [...response.parameters];
      this.inputs[3].values.unshift({
        label: this.translateService.instant("university.university-label"),
        value: 0
      });


      let i: number = this.inputs[3].values.findIndex((u: any) => {
        return u.value == this.inputs[3].realValue;
      });
      console.log("University");

      console.log(i);

      this.idUni = Number(this.inputs[3].values[i].value);
      console.log(this.idUni);

      this.inputs[3].value = this.inputs[3].values[i].label;
      this.inputs[3].realValue = this.idUni;
      console.log(this.inputs[3]);
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      //no notificar error
    });
  }

  getLanguages() {
    let sqlCountries: string = `SELECT id as value, descripcion as label, nombre FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 47`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getLanguages").subscribe((response: any) => {
      let json: any = JSON.parse(this.user.otros_idiomas_nativos);

      //console.log("idiomas");
      let idiomas: any = [...response.parameters];
      if (this.genericService.getLocale() == "en") {
        idiomas.forEach(element => {
          element.label = element.nombre
        });
      }

      json.forEach(element => {
        console.log("idioma selected ", element);
        idiomas.forEach((itm, index) => {
          if (element.value == itm.value) {
            idiomas[index] = { ...element };
          }
        });
      });


      this.inputs[5].values = [...idiomas];


      this.autoFilter = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this.mat_filter(value))
      );

      this.rendered = true;
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      //no notificar error
    });

    //let sqlCountries: string = `SELECT id as value, descripcion as label FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 47`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getLanguagesNes").subscribe((response2: any) => {
      let json2: any = JSON.parse(this.user.otros_idiomas_extranjeros);
      let idiomas2: any = [...response2.parameters];

      json2.forEach(element => {
        idiomas2.forEach((itm, index) => {
          if (element.value == itm.value) {
            idiomas2[index] = { ...element };
          }
        });
      });

      this.inputs[6].values = [...idiomas2];

    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }


  getLanguagesOld() {
    let sqlCountries: string = `SELECT id as value, descripcion as label FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 47`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getLanguages").subscribe((response: any) => {
      this.inputs[5].values = response.parameters;
      this.inputs[6].values = response.parameters;
      //console.log("------------");

      this.autoFilter = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this.mat_filter(value))
      );

      this.rendered = true;
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }

  getNiveles() {
    let sqlCountries: string = `SELECT id as value, descripcion as label, json, url FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 42`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getNiveles").subscribe((response: any) => {
      this.niveles = response.parameters;
    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }

  private mat_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.Items.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  //FOTOS
  async openSheet(): Promise<void> {

    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant("camera.options"),
      cssClass: 'action-sheets-olam',
      buttons: [

        {
          text: this.translateService.instant("camera.screenshot"),
          handler: () => {
            this.captureImage(false);
          },
        },
        {
          text: this.translateService.instant("camera.selectshot"),
          handler: () => {
            this.captureImage(true);
          },
        },
        {
          text: this.translateService.instant("camera.find"),
          handler: () => {
            let input: any = document.getElementById("hidden-file");
            input.click();


          }
        },
        {
          text: this.translateService.instant("delete"),
          role: 'destructive',
          handler: () => {
            this.b64Image = "";
          },
        },
        {
          text: this.translateService.instant("cancel"),
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          },
        },
      ],
    });


    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }

  async fileChange(evt) {
    let file: any = evt.target.files[0];
    if (file.size > 2000000) {
      this.alertService.warnAlert(
        "Ooops!",
        this.translateService.instant("alerts.big-file"),
        () => {
          ////console.log("hola");
        }
      );
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/svg+xml"
    ) {
      this.alertService.warnAlert(
        "Ooops!",
        this.translateService.instant("alerts.svg-file"),
        () => {
          ////console.log("hola");
        }
      );
    } else {
      let fileB64 = await this.utilService.toBase64(file);
      this.b64Image = fileB64;
    }
  }


  async captureImage(fromPhotogallery: boolean = false) {
    const options: CameraOptions = {
      quality: 90,
      destinationType: this.camerar.DestinationType.DATA_URL,
      encodingType: this.camerar.EncodingType.PNG,
      mediaType: this.camerar.MediaType.ALLMEDIA,
      allowEdit: true,
      sourceType: this.camerar.PictureSourceType.PHOTOLIBRARY
    }

    if (!fromPhotogallery) {
      options.sourceType = this.camerar.PictureSourceType.CAMERA;
    }

    this.camerar.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):

      // Can be set to the src of an image now
      this.b64Image = `data:image/png;base64,${imageData}`;
      //console.log(this.b64Image);
      this.changeDetectorRef.detectChanges();
    }, (err) => {
      // Handle error
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

  }

  async registerComplete() {
    let errores: number = 0;
    this.inputs.forEach(element => {
      if (!element.valuesMultiple && (element.value.length <= 0 || element.value == 0)) {
        errores++;
        element.error = true;
      } else if (element.valuesMultiple && element.valuesMultiple.length <= 0) {
        errores++;
        element.error = true;
      } else {
        element.error = false;
      }
    });

    if (errores < 0) {
      //this.inputs[10].error = false;
      this.loadingService.show();

      let sql: string = `UPDATE usuario SET 
      nombre = '${this.inputs[0].value}', 
      apellidos = '${this.inputs[1].value}', 
      id_pais = '${this.inputs[2].realValue}', 
      id_universidad = '${this.inputs[3].realValue}', 
      otros_idiomas_nativos = '${JSON.stringify(this.inputs[5].valuesMultiple)}', 
      otros_idiomas_extranjeros = '${JSON.stringify(this.inputs[6].valuesMultiple)}', 
      informacion = '${this.inputs[7].value}', 
      titulacion = '${this.inputs[4].value}' 
      WHERE id = ${this.user.id}`;

      console.log(sql);

      this.sqlGenericService.excecuteQueryString(sql).subscribe((response: any) => {

        //let returnId: any = response.parameters;
        let requestFile: any = {
          b64: this.b64Image,
          id: this.user.id,//usuario de nueva creacion
          extension: "png",
          table: "usuario"
        };

        //https://servidor2247.el.controladordns.com:2083
        //olamsyst
        //48Hn*8o4Rqy
        this.genericService.sendPostRequest(environment.loadFileOnly, requestFile).subscribe((resp2: any) => {
          //Se registra correctamente nuevo usuario
          this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("alerts.update-profile"));

          let sqlLogin: string = `SELECT * FROM usuario WHERE password = SHA2(MD5(UNHEX(SHA2('${this.inputs[9].value}',512))),224) AND username = '${this.inputs[8].value}'`;
          this.sqlGenericService.excecuteQueryString(sqlLogin).subscribe((login: any) => {
            this.loadingService.hide();
            localStorage.setItem("userSessionConnectandem", JSON.stringify(login.parameters[0]));
            //se actualiza el usuario en sesion local
          }, (err: HttpErrorResponse) => {
            this.loadingService.hide();
            window.history.back();
          });

        }, (err: HttpErrorResponse) => {
          this.loadingService.hide();
          this.alertService.errorAlert("Oops", this.translateService.instant("alerts.update-profile-error"));
        });

      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.alertService.errorAlert("Oops", this.translateService.instant("alerts.update-profile-error"));
      });



    }

  }

  realValue(element) {
    //console.log(element);
    if (element.length > 0) {
      this.inputs[2].realValue = element[0].value;
    }
  }

  realValue2(element) {
    //console.log(element);
    if (element.length > 0) {
      this.inputs[3].realValue = element[0].value;
    }
  }

  lastSelectionChange(event: any) {
    this.operationArray(event, 5, false);
  }

  lastSelectionChange2(event: any) {
    //console.log("dispara");
    this.operationArray(event, 6, true);
  }

  operationArray(event, posit, withAlert: boolean = true) {
    let index: any = -1;//this.inputs[6].valuesMultiple.findIndex(x => x.value === event.value);

    index = this.inputs[posit].valuesMultiple.findIndex(
      (cardGral: any) => {
        return cardGral.value == event[0].value;
      }
    );

    let rmv: boolean = index != -1;
    event = event[0];
    //console.log(event);
    //console.log(this.inputs[posit].valuesMultiple);
    //console.log(index);




    if (!rmv && !event.nivelID) {
      if (withAlert) {
        this.alertService.customizeAlert({
          type: null,
          title: null,
          text: null,
          showCancelButton: true,
          cancelButtonText: this.translateService.instant("cancel"),
          confirmButtonText: this.translateService.instant("alerts.accept"),
          showCloseButton: false,
          allowOutsideClick: true,
          customClass: "alerta-vista",
          didOpen: () => {
            let select: any = document.getElementById("idiomitapapayadecelaya2");
            let contenido: any = document.getElementById("contenido");
            contenido.innerHTML = `${this.translateService.currentLang == "es" ? this.niveles[0].json : this.niveles[0].url}`;
            select.addEventListener("change", (e: any) => {
              //console.log(e);
              let json: any = this.niveles.filter((element: any) => {
                return element.value == e.target.value;
              });
              console.log(json);

              contenido.innerHTML = this.translateService.currentLang == "es" ? json[0].json : json[0].url;

            });
          },
          html: `<section>
            <p>${this.translateService.instant("alerts.select-language-level")}</p>
    
            <select name="idioma" id="idiomitapapayadecelaya2" style="background-color: #fff;
            border: 1px solid #cacaca;
            border-radius: 4px;
            width: 100%;
            padding: 10px;">
              ${Object.keys(this.niveles).map((key) => {
            return "<option value='" + this.niveles[key].value + "'>" + this.niveles[key].label + "</option>"
          }).join("")}
            </select>
  
            <div id="contenido" class="ctnd">
              
            </div>
          
          </section>`
        }, () => {
          //this.inputs[5].valuesMultiple = event;

          let remove: boolean = false;

          if (index != -1) {
            //console.log(index);
            if (this.inputs[posit].valuesMultiple.length == 1) {
              remove = true;
              this.inputs[posit].valuesMultiple = [];
            } else {
              remove = true;
              this.inputs[posit].valuesMultiple = this.inputs[posit].valuesMultiple.slice(index, this.inputs[posit].valuesMultiple.length);
            }
          } else {
            event.checked = !event.checked;
            event["nivelID"] = (<any>document.getElementById("idiomitapapayadecelaya2")).value;
            this.inputs[posit].valuesMultiple.push(event);
            //console.log(this.inputs[posit].valuesMultiple);
            let id: any = document.getElementById("mat-select-value-3");
            console.log(id);

            id.children[0].children[0].innerHTML = "";
            this.inputs[posit].valuesMultiple.forEach(element => {

            });
          }


        }, () => {
        });
      } else {
        if (index != -1) {
          //console.log(index);
          if (this.inputs[posit].valuesMultiple.length == 1) {
            this.inputs[posit].valuesMultiple = [];
          } else {
            this.inputs[posit].valuesMultiple = this.inputs[posit].valuesMultiple.slice(index, this.inputs[posit].valuesMultiple.length);
          }
        } else {
          event.checked = !event.checked;
          event["nivelID"] = this.niveles[2].value;
          this.inputs[posit].valuesMultiple.push(event);
          //console.log(this.inputs[posit].valuesMultiple);

          this.inputs[posit].valuesMultiple.forEach(element => {

          });
        }
      }


    } else {

      //Aqui se borra el idioma actual
      //this.inputs[posit].valuesMultiple.forEach((itm: any) => {
      let position: any = -1;
      this.inputs[posit].values.forEach((element, index) => {
        if (event.value == element.value) {
          //console.log("identificado", element);
          position = index;
          delete element.checked;
          delete element.nivelID;
        }
      });
      //});

      //console.log("borrando");


      if (this.inputs[posit].valuesMultiple.length == 1) {
        this.inputs[posit].valuesMultiple = [];
      } else {
        //console.log(index);

        this.inputs[posit].valuesMultiple.splice(index, 1);
        //this.cards.splice(position, 1);
        //console.log(this.inputs[posit].valuesMultiple);
        this.changeDetectorRef.detectChanges();
      }

    }
  }

  clear(i: any) {
    //this.inputs[6].valuesMultiple;
    let el: any = this.inputs[6].valuesMultiple[i];
    console.log(this.inputs[6].values);
    console.log(el);

    this.inputs[6].values.forEach(element => {
      if (element.value == el.value) {
        delete element.nivelID;
        delete element.checked;
      }
    });
    this.inputs[6].valuesMultiple.splice(i, 1);
  }

  deleteUser() {
    this.alertService.confirmTrashAlert(() => {
      let sql: string = `DELETE FROM usuario WHERE id = ${this.user.id} AND 1=1`;
      //console.log(sql);
      this.loadingService.show();
      this.sqlGenericService.excecuteQueryStringReference(sql, "deleteUser").subscribe((resp: any) => {
        //Se registra correctamente nuevo usuario
        //console.log(resp);
        this.localStorageEncryptService.clearProperty("userSessionConnectandem");
        this.router.navigate(["/", "login"]);
        this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("profile.user-available"));
        this.loadingService.hide();
      }, (err: HttpErrorResponse) => {
        console.log(err);

        this.loadingService.hide();
      });
    }, this.translateService.instant("confirm"), this.translateService.instant("profile.delete-confirm"), this.translateService.instant("alerts.accept"));
  }
}
