import { TranslateService } from '@ngx-translate/core';
import { EventService } from './../../services/event.service';
import { Subscription } from 'rxjs';
import { environment, idEmpresa } from 'src/environments/environment.prod';
import { MeetingDatePage } from './../meeting-date/meeting-date.page';
import { Router } from '@angular/router';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './../../services/alert.service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { LoaderService } from './../../services/loading-service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { VideoRoomPage } from '../llamada/video-room/video-room.page';
import { SettingUpModalComponent } from '../llamada/shared/components/setting-up-modal/setting-up-modal.component';
import { GenericService } from 'src/app/services/generic.service';
import { FCMData, FCMJson, FCMNotification } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-reuniones',
  templateUrl: './reuniones.component.html',
  styleUrls: ['./reuniones.component.scss'],
})
export class ReunionesComponent implements OnInit, OnDestroy {

  public user: any = null;
  public reuniones: any[] = [];
  public reunionesTmp: any[] = [];

  public modal: any = null;
  public sus: Subscription = null;

  public data: any = {
    value: 0,
    placeholder: "status-meet",
    type: "select",
    realValue: this.user?.id_universidad,
    values: [

    ],
  };

  public susChangeLanguageDetector: Subscription = null;

  constructor(
    private loadingService: LoaderService,
    private sqlGenericService: SqlGenericService,
    private genericService: GenericService,
    private alertService: AlertService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private router: Router,
    public modalCtrl: ModalController,
    private actionSheetController: ActionSheetController,
    private changeDetectorRef: ChangeDetectorRef,
    private eventService: EventService,
    private translateService: TranslateService
  ) {
    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
  }

  ngOnInit() {
    this.cargaReuniones();
    this.getStatusMeet();
    this.sus = this.eventService.get("cierraModal").subscribe((r: any) => {
      try {
        this.modal.dismiss();
        this.router.navigate(["reuniones"]);
      } catch (error) {

      }
    });
    this.susChangeLanguageDetector = this.eventService.get("changeLanguage").subscribe((r: any) => {
      this.getStatusMeet();
    });
  }

  getStatusMeet() {
    let sql: string = `SELECT id as value, nombre as label, descripcion FROM catalogo WHERE id_tipo_catalogo = 48 AND id_empresa = ${idEmpresa}`;
    this.sqlGenericService.excecuteQueryStringReference(sql, "getStatusMeet").subscribe((response: any) => {
      console.log(response);



      console.log(localStorage.getItem("language"));

      if (JSON.parse(localStorage.getItem("language")) == "es") {
        response.parameters.forEach(element => {
          element.label = element.descripcion;
        });
      }
      response.parameters.unshift({
        label: "input.all",
        value: 0
      });
      this.data.values = response.parameters;


      console.log(this.data);

    }, (error: HttpErrorResponse) => {

    });
  }

  ngOnDestroy() {
    if (this.sus) {
      this.sus.unsubscribe();
    }
    if (this.susChangeLanguageDetector) {
      this.susChangeLanguageDetector.unsubscribe();
    }
  }

  cargaReuniones(spinner: boolean = true) {
    if (spinner) {
      this.loadingService.show();
    }
    let sql: string = `SELECT * FROM usuario_reunion ur
    LEFT JOIN reunion r
    ON (ur.id_reunion = r.id)
    WHERE ur.id_usuario = ${this.user.id}`;
    this.sqlGenericService.excecuteQueryStringReference(sql, "getReuniones").subscribe((response: any) => {
      this.reuniones = response.parameters;
      this.reunionesTmp = [...response.parameters];
      this.changeDetectorRef.detectChanges();
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }

  async openModal() {
    //this.router.navigate(["meeting"]);
    let modal = await this.modalCtrl.create({
      component: MeetingDatePage,
      componentProps: { modal: true },
      cssClass: 'setting-modal',
      backdropDismiss: false,
    });

    modal.present();
  }

  async openSheet(r: any): Promise<void> {

    //console.log(r);
    /*//console.log(new Date(r.fecha_inicio));
   //console.log(new Date("2020-06-24 22:57:36"));
     */


    let btns: any = [

    ];

    if (r.id_status_reunion != "261") {
      btns.push({
        text: this.translateService.instant("meet.entry-meet"),
        handler: async () => {
          let roomFactorize: any = r.room.split(" ");
          let rm: string = "";
          roomFactorize.forEach(element => {
            rm += `${element}tandemKEY`;
          });

          /* let modal = await this.modalCtrl.create({
            component: SettingUpModalComponent,
            componentProps: { modal: true, room: rm },
            cssClass: 'setting-modal',
            backdropDismiss: false,
          });

          modal.present(); */

          let sqlCommin: string = `UPDATE usuario_reunion SET presente = 1, fecha_entrada = now() WHERE id_usuario = ${this.user.id} AND id_reunion = ${r.id}`;
          //console.log(sqlCommin);
          this.loadingService.show();
          this.sqlGenericService.excecuteQueryStringReference(sqlCommin, "userPresent").subscribe(async (response: any) => {
            this.loadingService.hide();
            //AQUI LANZAR PUSH PARA NOTIFICAR AL USUARIO
            let sqlFCM: string = `SELECT u.* FROM usuario_reunion ur
            INNER JOIN usuario u 
            ON (u.id = ur.id_usuario)
            WHERE ur.id_usuario != ${this.user.id} AND ur.notificado = 0 AND ur.id_reunion = ${r.id}`;
            this.sqlGenericService.excecuteQueryStringReference(sqlFCM, "getUsersNotify").subscribe((users: any) => {
              if (users.parameters.length > 0) {
                users.parameters.forEach(element => {

                  let notification: FCMNotification = {
                    body: `${this.user.nombre} ha entrado a la reunión`,
                    title: `La reunión ha comenzado!`,
                    click_action: "FCM_PLUGIN_ACTIVITY",
                    image: null,
                    color: "#F07C1B",
                    "content-available": true
                  };

                  let dataFCM: FCMData = {
                    body: `${this.user.nombre} ha entrado a la reunión`,
                    title: `La reunión ha comenzado!`,
                    view: 1,
                    otherData: {
                      reunion: rm
                    }
                  };

                  let fcmd: FCMJson = {
                    to: `${element.token}`,
                    notification: notification,
                    data: dataFCM,
                    priority: "high"
                  };

                  this.genericService.sendPostRequest(environment.fcm, fcmd).subscribe((FCM) => {

                  });

                });
              }
            }, (error: HttpErrorResponse) => {

            });

            this.router.navigate(["meet", rm]);

            /* this.modal = await this.modalCtrl.create({
              component: VideoRoomPage,
              componentProps: { modal: true },
              cssClass: 'setting-modal',
              backdropDismiss: false,
            });
        
            this.modal.present(); */


          }, (error: HttpErrorResponse) => {
            this.alertService.errorAlert("Oops", this.translateService.instant("alerts.error"));
            this.loadingService.hide();
          });

        },
      });
    }

    if (r.id_status_reunion != "261") {
      btns.push({
        text: this.translateService.instant("meet.cancel-meet"),
        handler: async () => {
          let sqlUpdate: string = `UPDATE reunion SET id_status_reunion = 261, fecha_cancelacion = now(), id_usuario_cancelacion = ${this.user.id} WHERE id = ${r.id}`;
          //console.log(sqlUpdate);
          let userReunion: string = `SELECT u.* FROM usuario_reunion ur INNER JOIN usuario u ON (ur.id_usuario = u.id) WHERE ur.id_usuario != ${this.user.id} AND ur.id_reunion = ${r.id}`;
          //this.loadingService.show();
          this.sqlGenericService.excecuteQueryStringReference(sqlUpdate, "updateReunion").subscribe(() => {
            this.cargaReuniones(false);
            this.loadingService.hide();

            this.sqlGenericService.excecuteQueryStringReference(userReunion, "notifyUser").subscribe((r: any) => {
              console.log(r);

              r.parameters.forEach(element => {
                let notification: FCMNotification = {
                  body: `Reunión cancelada`,
                  title: `Revisa tus reuniones`,
                  click_action: "FCM_PLUGIN_ACTIVITY",
                  image: null,
                  color: "#F07C1B",
                  "content-available": true
                };

                let dataFCM: FCMData = {
                  body: `Reunión cancelada`,
                  title: `Revisa tus reuniones`,
                  view: 2,
                };

                let fcmd: FCMJson = {
                  to: `${element.token}`,
                  notification: notification,
                  data: dataFCM,
                  priority: "high"
                };

                this.genericService.sendPostRequest(environment.fcm, fcmd).subscribe((FCM) => {

                });
              });
              this.loadingService.hide();
            }, (error: HttpErrorResponse) => {
              this.loadingService.hide();
            });

          }, (error: HttpErrorResponse) => {
            this.loadingService.hide();
          });

          return await this.cargaReuniones(false);
        },
      });
    }

    if (r.id_usuario_anfitrion == this.user.id && r.id_status_reunion == "261") {
      btns.push({
        text: this.translateService.instant("meet.delete-meet"),
        handler: async () => {
          let sqlDelete: string = `DELETE FROM reunion WHERE id = ${r.id}`;
          let sqlDeletes: string = `DELETE FROM usuario_reunion WHERE id_reunion = ${r.id}`;
          //console.log(sqlDelete);

          this.loadingService.show();

          setTimeout(() => {
            this.loadingService.hide();
          }, 4000);

          this.sqlGenericService.excecuteQueryStringReference(sqlDeletes, "deleteReuniones").subscribe(() => {
            this.sqlGenericService.excecuteQueryStringReference(sqlDelete, "deleteReunion").subscribe(() => {
              this.loadingService.hide();
              this.cargaReuniones(false);
            }, (error: HttpErrorResponse) => {

              this.loadingService.hide();
            });
          }, (error: HttpErrorResponse) => {

            this.loadingService.hide();
          });

        },
      });
    }

    btns.push(
      {
        text: this.translateService.instant("cancel"),
        icon: 'close',
        handler: () => {
          //console.log('Cancel clicked');
        },
      });

    if (btns.length > 1) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translateService.instant("meet.enter-meet"),
        cssClass: 'action-sheets-olam',
        buttons: btns
      });


      await actionSheet.present();

      let a: any = await actionSheet.onDidDismiss();
    }
    //console.log('onDidDismiss resolved with role', a);
  }


  change(evt: any) {
    console.log(evt);
    console.log(this.data);
    this.reuniones = this.reunionesTmp;
    console.log(this.reuniones);

    if (this.data.value != 0) {
      let tmps = [];
      this.reuniones.forEach(element => {
        if (element.id_status_reunion == this.data.value) {
          tmps.push(element);
        }
      });

      this.reuniones = tmps;
    }
  }
}
