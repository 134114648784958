import { Platform } from "@ionic/angular";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import moment from "moment";
//declare var moment: any;
@Component({
  selector: "item-list-avatar",
  templateUrl: "./item-list-avatar.html",
  styleUrls: ["./item-list-avatar.scss"],
  //encapsulation: ViewEncapsulation.None
})
export class ItemListAvatarComponent implements OnDestroy, OnInit {
  @Input() data: any;

  constructor(private platform: Platform) {}

  public ngOnInit(): void {
    moment.locale("ES");
    //console.log(this.data.fecha_inicio);
    let separate = this.data.fecha_inicio.split(" ")[0];
    let date = new Date(separate);
    console.log(date);
    console.log(date.getDate());
    console.log("date IOS");

    this.data.dia =
      date.getDate() < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1;

    console.log(this.data.dia);

    this.data.mes = moment(date).format("MMM").toUpperCase().slice(0, -1);

    console.log(this.data.mes);

    console.log("-------");
    if (this.platform.is("ios")) {
      this.data.fecha_inicio=this.data.fecha_inicio.replace("-", "/");
      this.data.fecha_inicio=this.data.fecha_inicio.replace("-", "/");
    }
    console.log(this.data.fecha_inicio);
    

    let myDate: any = new Date(this.data.fecha_inicio);
    console.log(myDate);

    this.data.horaIni = moment(myDate).format("HH:mm");
    console.log("-------------------------------------------------------");
    console.log(this.data.horaIni);

    if (this.platform.is("ios")) {
      this.data.fecha_fin=this.data.fecha_fin.replace("-", "/");
      this.data.fecha_fin=this.data.fecha_fin.replace("-", "/");
    }
    myDate = new Date(this.data.fecha_fin);
    
    this.data.horaFin = moment(myDate).format("HH:mm");
  }

  public ngOnDestroy(): void {}

  removeId(name: string) {
    return name.split("-tan-dem-")[0];
  }
}
