import { EventService } from './../../services/event.service';
import { element } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';

import { environment, pathSettlementsCity } from './../../../environments/environment.prod';
import { GenericService } from './../../services/generic.service';
import { LoaderService } from './../../services/loading-service';
import { LoadingService } from './../../services/loading.service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { AlertService } from './../../services/alert.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { idEmpresa } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
//import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Device } from '@capacitor/device';
import { ModalRegisterPage } from '../modal-register/modal-register.page';
import * as moment from 'moment';
import { FcmService } from 'src/app/services/fcm.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  eye_icon = "eye-off-outline";

  public invitado: number = 0;

  public municipios: any[] = [];
  universidades: any[] = [];
  public roles: any[] = [];

  public valor: string = "";

  public inputs: any[] = [];

  public niveles: any[] = [];

  public tmp1: any[] = [];
  public tmp2: any[] = [];

  clickedImage: string;

  public b64Image: any = "";
  public epoch: any = null;

  constructor(
    private alertService: AlertService,
    private sqlGenericService: SqlGenericService,
    private loadingService: LoaderService,
    private route: ActivatedRoute,
    private genericService: GenericService,
    private router: Router,
    private menu: MenuController,
    private actionSheetController: ActionSheetController,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public modalCtrl: ModalController,
    private eventService: EventService,
    private fcmService: FcmService,
    private translateService: TranslateService,
    private camerar: Camera
  ) {
    this.menu.enable(false);
    let expiredSession = new Date();
    expiredSession.setDate(expiredSession.getDate() + 1);
    let str: string = JSON.stringify(expiredSession);

    let fecha = new Date(JSON.parse(str));


  }

  buildInputs() {
    //nombre completo 0
    this.inputs.push({
      required:true,
      placeholder: "university.name",
      value: "", error: false,
      type: "text",
      length: 100
    });
    //Apellidos 1
    this.inputs.push({
      required:true,
      placeholder: "university.last-name",
      value: "", error: false,
      type: "text",
      length: 300
    });
    //Pais 2
    this.inputs.push({
      required:false,
      placeholder: "university.country",
      value: null,
      type: "select",
      values: [

      ],
      realValue: 0
    });
    //Universidad 3
    this.inputs.push({
      required:false,
      placeholder: "university.university",
      value: null,
      type: "select",
      values: [

      ]
    });
    //Titulación 4
    this.inputs.push({
      required:false,
      placeholder: "university.title",
      value: this.invitado == 0 ? "" : "ADMIN", error: false,
      type: "text",
      length: 100
    });
    //Idioma nativo 5
    this.inputs.push({
      required:true,
      placeholder: "university.native-language",
      value: 0,
      type: "select",
      values: [

      ],
      valuesMultiple: []
    });
    //Idioma extranjero 6
    this.inputs.push({
      required:true,
      placeholder: "university.foreign-language",
      value: 0,
      type: "select",
      values: [

      ],
      valuesMultiple: []
    });
    //Sobre mí
    this.inputs.push({
      required:true,
      placeholder: "university.about-self",
      value: this.invitado == 0 ? "" : "ADMIN", error: false,
      type: "textarea",
      length: 500
    });
    //username
    this.inputs.push({
      required:true,
      placeholder: "university.email",
      value: "", error: false,
      type: "email",
      length: 100
    });
    //password
    this.inputs.push({
      required:true,
      placeholder: "university.pass",
      value: "", error: false,
      type: "password",
      length: 100
    });
    //password
    this.inputs.push({
      required:true,
      placeholder: "university.confirm-pass",
      value: "", error: false,
      type: "password",
      length: 100
    });
  }

  ngOnInit() {
    //this.alertService.errorAlert("Oops!","Datos incorrectos");
    this.invitado = Number(this.route.snapshot.params.invitado);
    this.buildInputs();
    this.getCounties();
    this.getUniversities();
    this.getLanguages();
    this.getNiveles();
  }


  change() {
    let id: any = document.getElementById("input-pass");
    if (id.type == "text") {
      id.type = "password";
      this.eye_icon = "eye-off-outline";
    } else {
      id.type = "text";
      this.eye_icon = "eye-outline";
    }
  }

  backing() {
    window.history.back();
  }

  async openSheet(): Promise<void> {

    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant("camera.options"),
      cssClass: 'action-sheets-olam',
      buttons: [

        {
          text: this.translateService.instant("camera.screenshot"),
          handler: () => {
            this.captureImage(false);
          },
        },
        {
          text: this.translateService.instant("camera.selectshot"),
          handler: () => {
            this.captureImage(true);
          },
        },
        {
          text: this.translateService.instant("camera.find"),
          handler: () => {
            let input: any = document.getElementById("hidden-file");
            input.click();


          }
        },
        {
          text: this.translateService.instant("delete"),
          role: 'destructive',
          handler: () => {
            this.b64Image = "";
          },
        },
        {
          text: this.translateService.instant("cancel"),
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          },
        },
      ],
    });


    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    //console.log('onDidDismiss resolved with role', role);
  }

  async fileChange(evt) {
    let file: any = evt.target.files[0];
    if (file.size > 2000000) {
      this.alertService.warnAlert(
        "Ooops!",
        this.translateService.instant("alerts.big-file"),
        () => {
          ////console.log("hola");
        }
      );
    } else if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg" &&
      file.type != "image/svg+xml"
    ) {
      this.alertService.warnAlert(
        "Ooops!",
        this.translateService.instant("alerts.svg-file"),
        () => {
          ////console.log("hola");
        }
      );
    } else {
      let fileB64 = await this.utilService.toBase64(file);
      this.b64Image = fileB64;
    }
  }

  async captureImage(fromPhotogallery: boolean = false) {
    const options: CameraOptions = {
      quality: 90,
      destinationType: this.camerar.DestinationType.DATA_URL,
      encodingType: this.camerar.EncodingType.PNG,
      mediaType: this.camerar.MediaType.ALLMEDIA,
      allowEdit: true,
      sourceType: this.camerar.PictureSourceType.PHOTOLIBRARY
    }

    if(!fromPhotogallery){
      options.sourceType= this.camerar.PictureSourceType.CAMERA;
    }
    
    this.camerar.getPicture(options).then((imageData) => {
     // imageData is either a base64 encoded string or a file URI
     // If it's base64 (DATA_URL):

    // Can be set to the src of an image now
    this.b64Image = `data:image/png;base64,${imageData}`;
    //console.log(this.b64Image);
    this.changeDetectorRef.detectChanges();
    }, (err) => {
     // Handle error
    });
  }

  getCounties() {
    let sqlCountries: string = `SELECT id as value, nombre as label, name_english FROM paises`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getCountries").subscribe((response: any) => {
      this.inputs[2].values = response.parameters;
      if (this.genericService.getLocale() == "en") {
        this.inputs[2].values.forEach(element => {
          element.label = element.name_english;
        });
      }
      this.inputs[2].values.unshift({
        label: "País",
        value: 0
      });

    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }

  getUniversities() {
    let sqlCountries: string = `SELECT id as value, descripcion as label, url FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 45`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getUniversities").subscribe((response: any) => {
      this.inputs[3].values = response.parameters;
      this.inputs[3].values.unshift({
        label: "Universidad",
        value: 0,
        url: null
      });
      if (this.invitado == 1) {
        this.inputs[3].realValue = this.inputs[3].values[4].value;
      }
    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }

  registro() {
    this.alertService.confirmTrashAlert(() => {

      const dialogRef = this.dialog.open(ModalRegisterPage, { panelClass: 'modal-fondo' });
      dialogRef.afterClosed().subscribe((result: any) => {
        //console.log('aqui se cerro', result);

      });

    }, "¡Universidad no encontrada!", "Tu universidad no esta dada de alta, ¿Deseas que la agreguemos?", "Agregar");
  }

  getLanguages() {
    let sqlCountries: string = `SELECT id as value, descripcion as label, nombre FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 47`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getLanguages").subscribe((response: any) => {
      this.inputs[5].values = [...response.parameters];
      if (this.genericService.getLocale() == "en") {
        this.inputs[5].values.forEach(element => {
          element.label = element.nombre;
        });
      }

    }, (error: HttpErrorResponse) => {
      //no notificar error
    });

    //let sqlCountries: string = `SELECT id as value, descripcion as label FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 47`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getLanguages").subscribe((response2: any) => {
      this.inputs[6].values = [...response2.parameters];

    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }

  getNiveles() {
    let sqlCountries: string = `SELECT id as value, descripcion as label, json, url FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 42`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getNiveles").subscribe((response: any) => {
      this.niveles = response.parameters;


    }, (error: HttpErrorResponse) => {
      //no notificar error
    });
  }


  changeSelection(event: any) {
    //console.log(event);
    this.inputs[5].valuesMultiple = [...event];
    //this.tmp1 = event;
  }

  changeSelectionExt(event: any) {
    //console.log(event);
    this.inputs[6].valuesMultiple = [...event];
  }

  lastSelectionChange(event: any) {
    this.operationArray(event, 5, false);
  }

  lastSelectionChange2(event: any) {
    //console.log("dispara");
    this.operationArray(event, 6, true);
  }

  operationArray(event, posit, withAlert: boolean = true) {
    let index: any = -1;//this.inputs[6].valuesMultiple.findIndex(x => x.value === event.value);

    index = this.inputs[posit].valuesMultiple.findIndex(
      (cardGral: any) => {
        return cardGral.value == event[0].value;
      }
    );

    let rmv: boolean = index != -1;
    event = event[0];
    //console.log(event);
    //console.log(this.inputs[posit].valuesMultiple);
    //console.log(index);




    if (!rmv && !event.nivelID) {
      if (withAlert) {
        this.alertService.customizeAlert({
          type: null,
          title: null,
          text: null,
          showCancelButton: true,
          confirmButtonText: this.translateService.instant("accept"),
          showCloseButton: false,
          allowOutsideClick: true,
          customClass: "alerta-vista",
          didOpen: () => {
            let select: any = document.getElementById("idiomitapapayadecelaya2");
            let contenido: any = document.getElementById("contenido");
            contenido.innerHTML = `${this.translateService.currentLang == "es" ? this.niveles[0].json : this.niveles[0].url}`;
            select.addEventListener("change", (e: any) => {
              //console.log(e);
              let json: any = this.niveles.filter((element: any) => {
                return element.value == e.target.value;
              });
              //console.log(json);

              contenido.innerHTML = this.translateService.currentLang == "es" ? json[0].json : json[0].url;

            });
          },
          html: `<section>
            <p>Selecciona tu nivel con el idioma</p>
    
            <select name="idioma" id="idiomitapapayadecelaya2" style="background-color: #fff;
            border: 1px solid #cacaca;
            border-radius: 4px;
            width: 100%;
            padding: 10px;">
              ${Object.keys(this.niveles).map((key) => {
            return "<option value='" + this.niveles[key].value + "'>" + this.niveles[key].label + "</option>"
          }).join("")}
            </select>
  
            <div id="contenido" class="ctnd">
              
            </div>
          
          </section>`
        }, () => {
          //this.inputs[5].valuesMultiple = event;

          let remove: boolean = false;

          if (index != -1) {
            //console.log(index);
            if (this.inputs[posit].valuesMultiple.length == 1) {
              remove = true;
              this.inputs[posit].valuesMultiple = [];
            } else {
              remove = true;
              this.inputs[posit].valuesMultiple = this.inputs[posit].valuesMultiple.slice(index, this.inputs[posit].valuesMultiple.length);
            }
          } else {
            event.checked = !event.checked;
            event["nivelID"] = (<any>document.getElementById("idiomitapapayadecelaya2")).value;
            this.inputs[posit].valuesMultiple.push(event);
            //console.log(this.inputs[posit].valuesMultiple);

            this.inputs[posit].valuesMultiple.forEach(element => {

            });
          }


        }, () => {
        });
      } else {
        if (index != -1) {
          //console.log(index);
          if (this.inputs[posit].valuesMultiple.length == 1) {
            this.inputs[posit].valuesMultiple = [];
          } else {
            this.inputs[posit].valuesMultiple = this.inputs[posit].valuesMultiple.slice(index, this.inputs[posit].valuesMultiple.length);
          }
        } else {
          event.checked = !event.checked;
          event["nivelID"] = this.niveles[2].value;
          this.inputs[posit].valuesMultiple.push(event);
          //console.log(this.inputs[posit].valuesMultiple);

          this.inputs[posit].valuesMultiple.forEach(element => {

          });
        }
      }


    } else {

      //Aqui se borra el idioma actual
      //this.inputs[posit].valuesMultiple.forEach((itm: any) => {
      let position: any = -1;
      this.inputs[posit].values.forEach((element, index) => {
        if (event.value == element.value) {
          //console.log("identificado", element);
          position = index;
          delete element.checked;
          delete element.nivelID;
        }
      });
      this.inputs[posit].value = 0;

      //});

      //console.log("borrando");


      if (this.inputs[posit].valuesMultiple.length == 1) {
        this.inputs[posit].valuesMultiple = [];
      } else {
        //console.log(index);

        this.inputs[posit].valuesMultiple.splice(index, 1);
        //this.cards.splice(position, 1);
        //console.log(this.inputs[posit].valuesMultiple);
        this.changeDetectorRef.detectChanges();
      }

    }
  }


  async registerComplete() {
    let errores: number = 0;
    console.log(this.inputs);

    this.inputs.forEach(element => {
      if (!element.valuesMultiple && !element.realValue && (element.value?.length <= 0 || element.value == 0 || !element.value) && element.required) {
        errores++;
        element.error = true;
      } else if (element.valuesMultiple && !element.realValue && element.valuesMultiple?.length <= 0 && this.invitado == 0 && element.required) {
        errores++;
        element.error = true;
      } else if (element.realValue && (element.realValue?.length == 0 || element.realValue == 0) && element.required) {
        element.error = true;
      } else {
        element.error = false;
      }
    });

    console.log(errores);

    if (this.inputs[9].value != this.inputs[10].value) {
      this.inputs[10].error = true;
      this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("password.no-match"));
    } else if (!this.inputs[9].value.match(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/)) {
      this.inputs[9].error = true;
      this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("report.pattern"));
    } else if (errores > 0) {
      this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("alerts.required"));
    } else {
      this.inputs[10].error = false;
      this.loadingService.show();

      //console.log(this.inputs);

      const info = await Device.getId();
      //console.log(info.uuid);

      let token: string = "fromWEB";

      //let sql: string = `SELECT * FROM usuario WHERE password = SHA2(MD5(UNHEX(SHA2('${this.data.pass.value}',512))),224) AND username = '${this.data.email.value}'`;
      let sqlSearch: string = `SELECT * FROM usuario WHERE username = '${this.inputs[8].value}'`;


      this.sqlGenericService.excecuteQueryString(sqlSearch).subscribe((search: any) => {

        if (search.parameters.length <= 0) {
          let tz: any = Intl.DateTimeFormat().resolvedOptions();
          let jsonDisponibilidad: any = {
            "dias": {
              "L": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "M": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "Mi": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "J": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "V": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "S": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              },
              "D": {
                "available": true,
                "horas": {
                  "inicio": "09:00:00",
                  "fin": "18:00:00"
                }
              }
            },
            
          };

          this.epoch = new Date().getTime();
          let sql: string = `INSERT INTO usuario (id_tipo_usuario, id_empresa, uuid, token, nombre, apellidos, language, password, username, id_pais, id_universidad, otros_idiomas_nativos, otros_idiomas_extranjeros, informacion, titulacion, tz, code_verificacion, disponibilidad_json) VALUES ('223', '188', '${info.uuid}', '${token}', '${this.inputs[0].value}', '${this.inputs[1].value}', 'es', SHA2(MD5(UNHEX(SHA2('${this.inputs[9].value}',512))),224), '${this.inputs[8].value}', ${this.inputs[2].realValue ? `${this.inputs[2].realValue}` : null}, ${this.inputs[3].realValue ? `${this.inputs[3].realValue}` : null}, '${JSON.stringify(this.inputs[5].valuesMultiple)}', '${JSON.stringify(this.inputs[6].valuesMultiple)}','${this.inputs[7].value}', '${this.inputs[4].value ?? ""}', '${tz.timeZone}', '${this.epoch}', '${JSON.stringify(jsonDisponibilidad)}')`;
          console.log(sql);
          
          this.sqlGenericService.excecuteQueryString(sql).subscribe((response: any) => {
            let returnId: any = response.parameters;
            if (this.b64Image && this.b64Image.length > 0) {
              let requestFile: any = {
                b64: this.b64Image,
                id: returnId,//usuario de nueva creacion
                extension: "png",
                table: "usuario"
              };

              this.genericService.sendPostRequest(environment.loadFileOnly, requestFile).subscribe((resp2: any) => {
                //Se registra correctamente nuevo usuario
                //this.alertService.successAlert("Bien!", "Te hemos registrado con éxito");



                //////////////////////CORREO////////////////////
                this.sendCorreo();

                /////////////////////////////////////////////////

              }, (err: HttpErrorResponse) => {
                this.loadingService.hide();
                this.alertService.errorAlert("Oops!", this.translateService.instant("register.error"));
              });
            } else {
              this.sendCorreo();
            }

          }, (error: HttpErrorResponse) => {
            this.loadingService.hide();
            this.alertService.errorAlert("Oops!", this.translateService.instant("register.error"));
          });
        } else {
          this.loadingService.hide();
          this.alertService.warnAlert("Oops!", `${this.translateService.instant("report.the-user")} ${this.inputs[8].value} ${this.translateService.instant("report.registered")}`);
        }
      }, (err: HttpErrorResponse) => {
        this.loadingService.hide();
        this.alertService.errorAlert("Oops!", this.translateService.instant("register.error"));
      });



    }

  }

  sendCorreo() {

    let request: any = {
      asunto: this.translateService.instant("register.register"),
      from: "soporte@egasystems.com",
      name: "soporte@egasystems.com",
      to: this.inputs[8].value,
      cuerpo: `<section>
    <div style="background-color: #006b89;
    text-align: center;padding: 8px;">
      <p style="color: #fff;margin: 0;font-size: 20px;">${this.translateService.instant("university.automatic")}</p>
    </div>
    <div style="padding: 10px;border: 1px solid #c8c8c8;">
      <p style="color: #000;">${this.translateService.instant("login.hi")} ${this.inputs[0].value} ${this.inputs[1].value}, ${this.translateService.instant("register.validate")}</p>
      
      <a href="http://conectadem.egasystems.com/identificar/${this.epoch}"><button style="color: #fff;
        background-color: #006b89;
        font-size: 16px;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 1px 1px 1px #123;
        margin-bottom: 20px;
        min-width: 200px;
        cursor: pointer;" >${this.translateService.instant("register.id")}</button></a>

      <p style="color: #000;">${this.translateService.instant("university.link")}</p>
      <a href="http://conectadem.egasystems.com/identificar/${this.epoch}">http://conectadem.egasystems.com/identificar/${this.epoch}</a>
    </div>
  </section>`
    };

    this.genericService.sendPostRequest(environment.mail, request).subscribe((response: any) => {
      this.loadingService.hide();
      //window.history.back();
      //se hace un update de token del usuario
      //this.fcmService.updateTokenUser();
      //
      this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("register.spam"));
      this.router.navigate(["login",this.invitado == 0 ? "0" : "1"]);
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      this.alertService.errorAlert("Oops!", this.translateService.instant("alerts.error"));
    });
  }

  async goToForm(): Promise<void> {
    //console.log("crear tarjeta");
    let modal = await this.modalCtrl.create({
      component: ModalRegisterPage,
      componentProps: {},
      cssClass: 'setting-modal',
      backdropDismiss: false,
    });
    modal.present();
  }

  realValue(element) {
    //console.log(element);
    if (element.length > 0) {
      this.inputs[2].realValue = element[0].value;
    }
  }

  realValue2(element) {
    //console.log(element);
    if (element.length > 0) {
      this.inputs[3].realValue = element[0].value;
    }
  }

  clear(i: any) {
    //this.inputs[6].valuesMultiple;
    let el: any = this.inputs[6].valuesMultiple[i];
    console.log(this.inputs[6].values);
    console.log(el);

    this.inputs[6].values.forEach(element => {
      if (element.value == el.value) {
        delete element.nivelID;
        delete element.checked;
      }
    });
    this.inputs[6].valuesMultiple.splice(i, 1);
  }
}
