import { VideoRoomPage } from './pages/llamada/video-room/video-room.page';
import { DashboardPage } from './pages/llamada/dashboard/dashboard.page';
import { MatchPage } from './pages/match/match.page';
import { EditProfilePage } from './pages/edit-profile/edit-profile.page';
import { LlamadaPage } from './pages/llamada/llamada.page';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { ReunionesComponent } from './pages/reuniones/reuniones.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RootGuard } from './guards/root.guard';
import { AdministratorComponent } from './pages/administrador/administrator/administrator.component';
import { TriviaAdmonPage } from './pages/administrador/trivia-admon/trivia-admon.page';
import { AboutComponent } from './pages/home/about/about.component';
import { RecuperarPage } from './pages/home/recuperar/recuperar.page';
import { TabsPage } from './pages/home/tabs.page';
import { LoginComponent } from './pages/login/login.component';
import { RegisterPage } from './pages/register/register.page';
import { MeetingDatePage } from './pages/meeting-date/meeting-date.page';
import { IdentificarPage } from './pages/identificar/identificar.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: "inicio",
    pathMatch: "full"
  },
  {
    path: 'llamada',
    redirectTo: "inicio",
    pathMatch: "full"
  },
  {
    path: 'login',
    redirectTo: "login/0",
    pathMatch: "full"
  },
  {
    path: 'inicio',
    component: TabsPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'login',
    //component: LoginComponent,
    children: [{
      path: ":invitado",
      component: LoginComponent,
    }]
  }, {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthGuard],
  }, {
    path: 'reuniones',
    component: ReunionesComponent,
    pathMatch: "full"
  },
  {
    path: "meeting",
    component: MeetingDatePage
  },
  {
    path: "match",
    component: MatchPage
  },
  {
    path: 'recuperar',
    children: [{
      path: ":id",
      component: RecuperarPage,
    }]
  }, {
    path: 'register',
    children: [{
      path: ":invitado",
      component: RegisterPage,
    }]
  },
  {
    path: "root",
    canActivateChild: [RootGuard],
    children: [
      {
        path: 'administrador',
        component: TriviaAdmonPage
        //loadChildren: () => import('./pages/administrador/trivia-admon/trivia-admon.module').then( m => m.TriviaAdmonPageModule)
      },
       /* {
        path: 'admon',
        component: AdministratorComponent
      }, */
    ]
  },
  {
    path: 'onboarding',
    component: OnboardingPage
  },
  {
    path: 'llamada',
    children: [
      {
        path: ":room",
        component: LlamadaPage
      },
    ]
  },
  {
    path: 'meet',
    children: [
      {
        path: ':roomName',
        component: VideoRoomPage
      },
    ]
  },
  {
    path: 'identificar/:epoch',
    component: IdentificarPage
  },
  {
    path: 'edit-profile',
    component: EditProfilePage,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./pages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
