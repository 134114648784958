import { TranslateService } from '@ngx-translate/core';
import { MeetingDatePage } from './../meeting-date/meeting-date.page';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loading-service';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
//import * as moment from 'moment';
//import * as moment from 'moment-timezone';
import * as moment from 'moment-timezone';
// import Swiper JS
declare var Swiper;
declare var $: any;
declare var Hammer;
import 'swiper/swiper-bundle.css';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalController, Platform } from '@ionic/angular';
import { MeetingDatePageModal } from '../meeting-date-modal/meeting-date.page';

@Component({
  selector: 'app-match',
  templateUrl: './match.page.html',
  styleUrls: ['./match.page.scss'],
})
export class MatchPage implements OnInit {

  public epochNumber: any = null;
  public reintentar: boolean = false;
  public user: any = null;
  public users: any = [];
  public total: number = 0;
  public modalOpen: any = null;
  public modalOpenIF: any = null;
  constructor(
    public themeService: ThemeService,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private loadingService: LoaderService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private localStorageEncryptService: LocalStorageEncryptService,
    public modalCtrl: ModalController,
    public platform: Platform,
    private translateService: TranslateService
  ) {
    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
  }

  ngOnInit() {
    this.loadingService.show(this.translateService.instant("alerts.looking"));

    let jsonExtranjeros: any = JSON.parse(this.user.otros_idiomas_extranjeros);
    let jsonNativos: any = JSON.parse(this.user.otros_idiomas_nativos);

    let stringREGEXNativo: string = "";
    let stringREGEXExtranjero: string = "";

    jsonNativos.forEach(itm => {
      stringREGEXNativo += `${itm.label}|`;
      if (itm.nivelID) {
        stringREGEXNativo += `${itm.nivelID}|`;
      }
    });

    jsonExtranjeros.forEach(itm => {
      stringREGEXExtranjero += `${itm.label}|`;
      if (itm.nivelID) {
        stringREGEXExtranjero += `${itm.nivelID}|`;
      }
    });

    stringREGEXExtranjero = stringREGEXExtranjero.slice(0, -1);
    stringREGEXNativo = stringREGEXNativo.slice(0, -1);

    //tz = '${this.user.tz}' AND <-----
    let sql: string = `SELECT * FROM usuario WHERE id != ${this.user.id}`; // WHERE tz = '${this.user.tz}'`;

    console.log(sql);

    this.sqlGenericService.excecuteQueryStringReference(sql, "matchTandem").subscribe((response: any) => {
      if (response.parameters.length > 0) {
        let usersTemp = [...response.parameters];


        let userMatch1 = [];
        let userMatch2 = [];
        let json: any = JSON.parse(this.user.otros_idiomas_nativos);
        console.log("mis idiomas", json);

        let json2: any = JSON.parse(this.user.otros_idiomas_extranjeros);

        usersTemp.forEach(us => {
          let idiomas: any = JSON.parse(us.otros_idiomas_nativos);
          console.log(idiomas);

          idiomas.forEach(element => {//Idiomas nativos de cada usuario
            json.forEach(nativos => {//recorro MIS idiomas nativos
              if (nativos.value != element.value) {// si los idiomas nativos no son el mismo se agregan
                let i: number = userMatch1.findIndex((u: any) => {
                  return u.id == us.id
                });

                if (i < 0) {
                  userMatch1.push(us);
                }
              }
            });
          });
        });

        userMatch2 = [...userMatch1];
        userMatch1.forEach(us => {
          let idiomas: any = JSON.parse(us.otros_idiomas_extranjeros);
          us.idioMatch = idiomas;
          idiomas.forEach(element => {
            json2.forEach(nativos => {
              if (nativos.value == element.value) {
                let i: number = userMatch2.findIndex((u: any) => {
                  return u.id == us.id
                });

                if (i < 0) {
                  userMatch2.push(us);
                }
              }
            });
          });
        });

        console.log(userMatch2);

        let timeUserMatch = moment.tz(new Date(), this.user.tz).format();
        let userMatch3 = [...userMatch2];
        userMatch2.forEach(element => {
          //console.log(reffr);
          //this.time = moment().tz('America/New_York').format('HH:mm:ss z')
          let jsonDisponibilidade: any = JSON.parse(element.disponibilidad_json);
          let keys: any = Object.keys(jsonDisponibilidade.dias);

          keys.forEach(key => {
            let jsonDisponibilidad: any = jsonDisponibilidade.dias[key];
            console.log(jsonDisponibilidad);

            if (jsonDisponibilidad.available) {
              console.log(jsonDisponibilidad);
              let horaIni: any = jsonDisponibilidad.horas.inicio.split(":");
              let horaFin: any = jsonDisponibilidad.horas.fin.split(":");
              var today = new Date();

              var myIni = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaIni[0], horaIni[1], horaIni[2]);

              var myEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaFin[0], horaFin[1], horaFin[2]);


              /* console.log(moment.tz(new Date(), "America/New_York").format());
              console.log(moment.tz(new Date(), "Europe/Madrid").format()); */
              var dateTZUser = moment.tz(new Date(), element.tz);

              var dateTZUserIni = moment.tz(myIni, element.tz);
              var dateTZUserEnd = moment.tz(myEnd, element.tz);

              if (dateTZUser >= dateTZUserIni && dateTZUser <= dateTZUserEnd) {
                //console.log("aplica");
                let i: number = 0;
                userMatch3.forEach(element2 => {
                  if (element2.id == element.id) {
                    i++;
                  }
                });

                if (i <= 0) {
                  userMatch3.push(element);
                }
              } else {
                //console.log("no aplica");
              }
            }
          });

        });

        this.users = [...userMatch3];//lista de usuarios que hicieron match completo
        this.total = this.users.length;

        setTimeout(() => {
          this.loadingService.hide();
          this.init2();
        }, 1000);
      } else {
        this.loadingService.hide();
        this.reintentar = true;
        this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("alerts.no-match-tz"));
      }
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      this.reintentar = true;
      this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("alerts.no-match"));
      //no notificar error
    });
  }

  init2() {
    let self: any = this;
    var tinderContainer = Array.from(document.getElementsByClassName('tinder'))[0];

    var nope = document.getElementById('nope');
    var love = document.getElementById('love');

    function initCards(card2: any = null, index2: any = null) {
      var newCards = document.querySelectorAll('.tinder--card:not(.removed)');

      newCards.forEach(function (card: any, index) {

        let editable: any = card2 ?? card;
        if (card2) {

          card2.classList.remove('removed');
        }
        var allCards = Array.from(document.getElementsByClassName('tinder--card'));
        editable.style.zIndex = allCards.length - index;
        editable.style.transform = 'scale(' + (20 - index) / 20 + ') translateY(-' + 30 * index + 'px)';
        editable.style.opacity = (10 - index) / 10;
      });

      tinderContainer.classList.add('loaded');
    }

    initCards();

    function reload() {
      var allCards = Array.from(document.getElementsByClassName('tinder--card'));


      allCards.forEach(function (el) {
        el.classList.remove('removed');
        var hammertime = new Hammer(el);

        hammertime.on('pan', function (event) {
          el.classList.add('moving');
        });

        hammertime.on('pan', function (event) {
          if (event.deltaX === 0) return;
          if (event.center.x === 0 && event.center.y === 0) return;

          tinderContainer.classList.toggle('tinder_love', event.deltaX > 0);
          tinderContainer.classList.toggle('tinder_nope', event.deltaX < 0);

          var xMulti = event.deltaX * 0.03;
          var yMulti = event.deltaY / 80;
          var rotate = xMulti * yMulti;

          if (event.target.className == "imgt" || event.target.className == "languages-match" || event.target.className == "tinder--cards") {
            event.target.parentNode.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
          } else {
            event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
          }
        });

        hammertime.on('panend', function (event) {
          console.log(event);

          el.classList.remove('moving');
          tinderContainer.classList.remove('tinder_love');
          tinderContainer.classList.remove('tinder_nope');

          var moveOutWidth = document.body.clientWidth;
          var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

          event.target.classList.toggle('removed', !keep);

          if (keep) {
            console.log("keep");

            event.target.style.transform = '';
          } else {
            var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
            var toX = event.deltaX > 0 ? endX : -endX;
            var endY = Math.abs(event.velocityY) * moveOutWidth;
            var toY = event.deltaY > 0 ? endY : -endY;
            var xMulti = event.deltaX * 0.03;
            var yMulti = event.deltaY / 80;
            var rotate = xMulti * yMulti;

            console.log("NO keep");


            event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';

            if (toX > 0) {
              let position: any = event.target.id.split("-");
              setTimeout(() => {
                initCards(document.getElementById("user-" + (self.users.length - 1)));
                //reload();
              }, 500);
              console.log(self.total);

              if (position[1] == (self.total - 1)) {
                console.log("initCards");
                reload();
              }
              //window.history.back();
              self.open(position[1]);
            } else {
              let position: any = event.target.id.split("-");

              setTimeout(() => {
                initCards(document.getElementById("user-" + (self.users.length - 1)));
                //reload();

              }, 500);
              console.log(self.total);

              if (position[1] == (self.total - 1)) {
                console.log("initCards");
                reload();
              }
            }
            initCards();
          }
        });
      });
    }

    reload();

    function createButtonListener(love) {
      return function (event) {
        var cards = document.querySelectorAll('.tinder--card:not(.removed)');
        var moveOutWidth = document.body.clientWidth * 1.5;

        if (!cards.length) return false;

        var card: any = cards[0];

        card.classList.add('removed');

        if (love) {
          card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)';

          let position: any = card.id.split("-");
          console.log(position);
          setTimeout(() => {
            initCards(document.getElementById("user-" + (self.users.length - 1)));
            //reload();
          }, 500);
          console.log(self.total);

          if (position[1] == (self.total - 1)) {
            console.log("initCards");
            reload();
          }
          //window.history.back();
          self.open(position[1]);
        } else {
          card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)';
          let position: any = card.id.split("-");

          //self.users.push({ ...self.users[Number(position[1])] });
          setTimeout(() => {
            initCards(document.getElementById("user-" + (self.users.length - 1)));
            //reload();
          }, 500);
          console.log(self.total);

          if (position[1] == (self.total - 1)) {
            console.log("initCards");
            reload();
          }
        }

        initCards();

        event.preventDefault();
      };
    }

    var nopeListener = createButtonListener(false);
    var loveListener = createButtonListener(true);

    nope.addEventListener('click', nopeListener);
    love.addEventListener('click', loveListener);

  }


  initOld() {
    let self: any = this;
    var tinderContainer = Array.from(document.getElementsByClassName('tinder'))[0];
    var allCards = Array.from(document.getElementsByClassName('tinder--card'));
    var nope = document.getElementById('nope');
    var love = document.getElementById('love');

    function writeLoveToServer() {
      //Execute cgi to update positive record
      //document.getElementById("question2").innerHTML = "whatever";
    }

    function writeNopeToServer() {
      //Execute cgi to update negative
    }

    function myDoubleClickFunction() {
      //TO DO: this function is called when user double clicks on card -> answer to question is displayed. See example on first and second cards. This function is called by all cards. 
    }

    function initCards(card: any = null, index: any = null) {
      var newCards = document.querySelectorAll('.tinder--card:not(.removed)');

      newCards.forEach(function (card: any, index) {
        card.style.zIndex = allCards.length - index;
        card.style.transform = 'scale(' + (20 - index) / 20 + ') translateY(-' + 30 * index + 'px)';
        card.style.opacity = (10 - index) / 10;
      });

      tinderContainer.classList.add('loaded');
    }

    initCards();

    allCards.forEach(function (el) {
      var hammertime = new Hammer(el);

      hammertime.on('pan', function (event) {
        el.classList.add('moving');
      });

      hammertime.on('pan', function (event) {
        if (event.deltaX === 0) return;
        if (event.center.x === 0 && event.center.y === 0) return;

        tinderContainer.classList.toggle('tinder_love', event.deltaX > 0);
        tinderContainer.classList.toggle('tinder_nope', event.deltaX < 0);

        var xMulti = event.deltaX * 0.03;
        var yMulti = event.deltaY / 80;
        var rotate = xMulti * yMulti;
        //console.log(event.target);
        if (event.target.className == "imgt" || event.target.className == "languages-match") {
          event.target.parentNode.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
        } else {
          event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
        }
      });

      hammertime.on('panend', function (event) {
        el.classList.remove('moving');
        tinderContainer.classList.remove('tinder_love');
        tinderContainer.classList.remove('tinder_nope');

        var moveOutWidth = document.body.clientWidth;
        var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

        event.target.classList.toggle('removed', !keep);

        if (keep) {
          event.target.style.transform = '';
        } else {
          var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
          var toX = event.deltaX > 0 ? endX : -endX;
          var endY = Math.abs(event.velocityY) * moveOutWidth;
          var toY = event.deltaY > 0 ? endY : -endY;
          var xMulti = event.deltaX * 0.03;
          var yMulti = event.deltaY / 80;
          var rotate = xMulti * yMulti;

          event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';

          if (toX > 0) {

            let position: any = event.target.id.split("-");

            window.history.back();
            self.open(position[1]);
          }

          initCards();
        }
      });
    });

    function createButtonListener(love) {
      return function (event) {
        var cards = document.querySelectorAll('.tinder--card:not(.removed)');
        var moveOutWidth = document.body.clientWidth * 1.5;

        if (!cards.length) return false;

        var card: any = cards[0];
        console.log(card);


        card.classList.add('removed');

        if (love) {
          //console.log(card);
          card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)';
          let position: any = event.target.id.split("-");

          window.history.back();
          self.open(position[1]);
        } else {
          card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)';
        }

        initCards();

        event.preventDefault();
      };
    }

    var nopeListener = createButtonListener(false);
    var loveListener = createButtonListener(true);

    nope.addEventListener('click', nopeListener);
    love.addEventListener('click', loveListener);
  }


  async open(position: any) {
    //console.log(this.users);
    //console.log(position);
    console.log(this.users[Number(position)]);

    let us: any = this.users[Number(position)];
    if (!this.modalOpenIF) {
      console.log(this.modalOpen);
      this.modalOpenIF = true;
      this.modalOpen = await this.modalCtrl.create({
        component: MeetingDatePageModal,
        componentProps: {
          user: us
        },
        cssClass: 'setting-modal',
        backdropDismiss: false,
      });
      this.modalOpen.present();
      this.modalOpen.onDidDismiss().then((detail: any) => {
        this.modalOpen = null;
        this.modalOpenIF = false;
      });
    }
  }


}
