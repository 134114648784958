import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserModel } from '../../models/user-model';
import { ModalController } from '@ionic/angular';
import { LocalStorageEncryptService } from 'src/app/services/local-storage-encrypt.service';

@Component({
    selector: 'chat-component',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
    @Input()
    user: any;

    @Output()
    messageReceived = new EventEmitter<any>();
    @Output()
    closeChat = new EventEmitter<any>();

    message: string;
    @Input()
    messageList: any[] = [];
    @Input()
    fotito: any = null;

    public usert: any = null;
    public data:any = null;

    constructor(public modalController: ModalController,
        private localStorageEncryptService: LocalStorageEncryptService
    ) {
        this.usert = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
    }

    ngOnInit() { }

    eventKeyPress(event) {
        if (event && event.keyCode === 13) {
            this.sendMessage();
        }
    }

    sendMessage(): void {
        if (this.user && this.message) {
            this.message = this.message.replace(/ +(?= )/g, '');
            if (this.message !== '' && this.message !== ' ') {
                const data:any = {
                    connectionId: this.user.getConnectionId(),
                    message: this.message,
                    nickname: this.user.getNickname(),
                    other: this.usert
                };
                //this.data = JSON.parse(data);
                this.user.getStreamManager().stream.session.signal({
                    data: JSON.stringify(data),
                    type: 'chat',
                });
                this.scrollToBottom();
                this.message = '';
            }
        }
    }

    scrollToBottom(): void {
        setTimeout(() => {
            try {
                const contentMessage = document.getElementById('message-wrap');
                contentMessage.scrollTop = contentMessage.scrollHeight;
            } catch (err) {
                //console.error(err);
            }
        }, 20);
    }

    dismiss() {
        this.modalController.dismiss();
    }

    onFocus() { }
}
