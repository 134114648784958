import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'button-tandem',
  templateUrl: './button-tandem.html',
  styleUrls: ['./button-tandem.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class ButtonTandemComponent implements OnDestroy, OnInit {

  @Input() extraClass: string;
  @Input() onTap: Function;


  constructor(
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {

  }

}
