import { ModalController } from '@ionic/angular';
import { GenericService } from 'src/app/services/generic.service';
import { LoaderService } from 'src/app/services/loading-service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-register',
  templateUrl: './modal-register.page.html',
  styleUrls: ['./modal-register.page.scss'],
})
export class ModalRegisterPage implements OnInit {
  inputs: any[] = [];
  constructor(
    private loadingService: LoaderService,
    private alertService: AlertService,
    private genericService: GenericService,
    private modalCtrl: ModalController,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.buildInputs();
  }

  buildInputs() {
    this.inputs.push({
      placeholder: "university.name",
      value: "", error: false,
      type: "text",
      length: 300
    });
    this.inputs.push({
      placeholder: "university.last-name",
      value: "", error: false,
      type: "text",
      length: 300
    });
    this.inputs.push({
      placeholder: "university.email",
      value: "", error: false,
      type: "text",
      length: 300
    });
    this.inputs.push({
      placeholder: "university.university",
      value: "", error: false,
      type: "text",
      length: 300
    });
    this.inputs.push({
      placeholder: "university.anio",
      value: "", error: false,
      type: "text",
      length: 300
    });
    this.inputs.push({
      placeholder: "university.comments",
      value: "", error: false,
      type: "textarea",
      length: 300
    });
  }

  backing() {
  }

  registro() {
    let errores: number = 0;
    this.inputs.forEach(element => {
      if (!element.valuesMultiple && (element.value.length <= 0 || element.value == 0)) {
        errores++;
        element.error = true;
      } else if (element.valuesMultiple && element.valuesMultiple.length <= 0) {
        errores++;
        element.error = true;
      } else {
        element.error = false;
      }
    });

    this.loadingService.show();
    if (errores > 0) {
      this.alertService.warnAlert("Oops!", this.translateService.instant("alerts.required-some"));
      this.loadingService.hide();
    } else {
      let request: any = {
        asunto: this.translateService.instant("university.university-new"),
        from: "soporte@egasystems.com",
        name: "soporte@egasystems.com",
        to: "connectandem@institutofranklin.net",
        cuerpo: `<section>
      <div style="background-color: #006b89;
      text-align: center;padding: 8px;">
        <p style="color: #fff;margin: 0;font-size: 20px;">${this.translateService.instant("university.automatic")}</p>
      </div>
      <div style="padding: 10px;border: 1px solid #c8c8c8;">
        <p style="color: #000;"> ${this.translateService.instant("university.up")}</p>
        <p style="color: #000;">
        ${this.translateService.instant("university.petition")}
        </p>
        <p><strong>${this.translateService.instant("university.name")}:</strong> ${this.inputs[0].value}</p>
        <p><strong>${this.translateService.instant("university.last-name")}:</strong> ${this.inputs[1].value}</p>
        <p><strong>${this.translateService.instant("university.contact")}:</strong> <a href='mailto:${this.inputs[2].value}'>${this.inputs[2].value}</a></p>
        <p><strong>${this.translateService.instant("university.university")}:</strong> ${this.inputs[3].value}</p>
        <p><strong>${this.translateService.instant("university.anio")}:</strong> ${this.inputs[4].value}</p>
        <p><strong>${this.translateService.instant("university.comments")}:</strong> ${this.inputs[5].value}</p>

       

        <a href="http://conectadem.egasystems.com/root/administrador"><button style="color: #fff;
          background-color: #006b89;
          font-size: 16px;
          padding: 8px;
          border-radius: 8px;
          box-shadow: 1px 1px 1px #123;
          margin-bottom: 20px;
          min-width: 200px;
          cursor: pointer;" >${this.translateService.instant("university.button")}</button></a>

        <p style="color: #000;">${this.translateService.instant("university.link")}</p>
        <a href="http://conectadem.egasystems.com/root/administrador">http://conectadem.egasystems.com/root/administrador</a>
      </div>
    </section>`
      };

      this.genericService.sendPostRequest(environment.mail, request).subscribe((response: any) => {
        this.loadingService.hide();
        this.modalClosed();
        this.alertService.successAlert(this.translateService.instant("alerts.success"), this.translateService.instant("university.university-notification"));
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.alertService.errorAlert("Oops!", this.translateService.instant("alerts.error"));
      });
    }
  }

  modalClosed() {
    this.modalCtrl.dismiss();
    //r si se requiere hacer algo al cerrar modal
  }
}
