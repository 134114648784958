import { LocalStorageEncryptService } from 'src/app/services/local-storage-encrypt.service';
import { LoaderService } from './../../services/loading-service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { LoadingService } from './../../services/loading.service';
import { Platform, PopoverController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme.service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { WebRTCProvider } from 'src/app/services/webrtc.service';
import { OpcionesComponent } from 'src/app/components/opciones/opciones.component';
import { OpcionesReporteComponent } from '../opciones_reporte/opciones_reporte.component';
import { idEmpresa } from 'src/environments/environment.prod';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

declare var cordova: any;// tentativo error
declare let RTCPeerConnection: any;
declare var MediaRecorder: any;
declare var JitsiMeetExternalAPI: any;

declare var $: any;

@Component({
  selector: 'app-llamada',
  templateUrl: './llamada.page.html',
  styleUrls: ['./llamada.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LlamadaPage implements OnInit, AfterViewInit {

  public isMouseDown: any;
  public initX: any;
  public initY: any;
  public height: any;
  public width: any
  public divOverlay: any;
  public fije: any;

  public intervalo: any = null;

  /*WEBRTC */
  @ViewChild('video') video: ElementRef;
  mediaRecorder;
  chunks = [];
  // channel: any = [];
  pc1: any;
  pc2: any;
  offerOptions = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1
  };

  callActive: boolean = false;
  pc: any;
  pcRemote: any;
  localStream: any;
  senderId: string;
  @ViewChild("me") me: any;
  @ViewChild("remote") remote: any;


  public bigVideo: boolean = false;

  public milissegundos: any;
  public segundos: any;
  public minutos: any;
  public horas: any;
  public anexarHoras: any;
  public anexarSegundos: any;
  public anexarMinutos: any;

  userId: string;
  partnerId: string;
  myEl: HTMLMediaElement;
  partnerEl: HTMLMediaElement;

  domain: string = "meet.jit.si"; // For self hosted use your domain
  room: string;
  options: any;
  api: any;
  user: any;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  constructor(
    public themeService: ThemeService,
    private plt: Platform,
    private diagnostic: Diagnostic,
    private androidPermissions: AndroidPermissions,
    public webRTC: WebRTCProvider,
    public elRef: ElementRef,
    private popoverController: PopoverController,
    private loadingService: LoaderService,
    private sqlGenericService: SqlGenericService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
  }

  async ngOnInit() {
    this.room = String(this.route.snapshot.params.room);
    if (this.room.length > 0) {
      
    }else{
      this.router.navigate(["/"]);
    }
  }

  ionViewWillEnter() {
   //console.log('ionViewWillEnter ')
  }

  ionViewDidLoad() {
   //console.log('ionViewDidLoad ')
  }
  ionViewDidEnter() {
   //console.log('ionViewDidEnter ')
  }
  ionViewWillLeave() {
   //console.log('ionViewWillLeave  ')
  }
  ionViewDidLeave() {
   //console.log('ionViewDidLeave   ')
  }
  ionViewWillUnload() {
   //console.log('ionViewWillUnload    ')
  }

  call() {
   //console.log(this.partnerId);

    this.webRTC.call(this.partnerId);
  }

  afterInit() {
   //console.log("------------------------AFTER-----------------------");

    this.options = {
      roomName: this.room,
      width: 900,
      height: 500,
      configOverwrite: { 
        subject: 'Tandem', 
        prejoinPageEnabled: false, 
        disableDeepLinking: true, 
        startWithAudioMuted: true 
      },
      disableDeepLinking: true,
      interfaceConfigOverwrite: {
        // overwrite interface properties
        DEFAULT_LOGO_URL: "assets/imgs/connectandemLogo.png",
        SHOW_JITSI_WATERMARK: false,
        HIDE_INVITE_MORE_HEADER: false,
        HIDE_DEEP_LINKING_LOGO: false,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
        DISABLE_FOCUS_INDICATOR: false,
        DEFAULT_BACKGROUND: this.themeService.getColorHex(),
        DEFAULT_WELCOME_PAGE_LOGO_URL: "assets/imgs/connectandemLogo.png",
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_POWERED_BY: false,
        JITSI_WATERMARK_LINK: false
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: `${this.user.nombre} ${this.user.apellidos}`
      }
    }

   //console.log(this.options);


    this.api = new JitsiMeetExternalAPI(this.domain, this.options);
    this.api.on('readyToClose', () => {
     //console.log('opaaaaa');
    });

    // declarar variável horas e atribuir ligação ao tempo definido em html 
    this.horas = 0;

    // declarar variável minutos e atribuir ligação ao tempo definido em html	  		
    this.minutos = 0;

    // declarar variável segundos e atribuir ligação ao tempo definido em html
    this.segundos = 0;

    // declarar variável milissegundos e atribuir ligação ao tempo defindo em html
    this.milissegundos = 0;


    // declarar variável para anexar horas e atribuir ligação ao elemento id definido em html 
    this.anexarHoras = document.getElementById("horas");

   //console.log(this.anexarHoras);

    // declarar variável para anexar minutos e atribuir ligação ao elemento id definido em html 
    this.anexarMinutos = document.getElementById("minutos");

    // declarar variável para anexar segundos e atribuir ligação ao elemento id definido em html 
    this.anexarSegundos = document.getElementById("segundos");


    this.intervalo = setInterval(() => {
      this.duracaoContagem();
    }, 10);
  }

  ngAfterViewInit() {
    this.afterInit();
    //this.duracaoContagem();
  }

  // definir como vai ser a contagem   
  duracaoContagem() {
    // começar contagem dos milissegundos
    this.milissegundos++;



    // começar contagem dos segundos 
    // se os milissegundos forem superiores a 99 	
    if (this.milissegundos > 99) {
      this.segundos++;
      this.anexarSegundos.innerHTML = "0" + this.segundos;
      this.milissegundos = 0;
    }


    if (this.segundos > 9) {
      this.anexarSegundos.innerHTML = this.segundos;
    }

    // começar contagem dos minutos	
    // se os segundos forem superiores a 59
    if (this.segundos > 59) {
      this.minutos++;
      this.anexarMinutos.innerHTML = "0" + this.minutos;
      this.segundos = 0;
      this.anexarSegundos.innerHTML = "0" + 0;
    }


    if (this.minutos > 9) {
      this.anexarMinutos.innerHTML = this.minutos;
    }

    // começar contagem das horas 
    // se os minutos forem superiores a 59
    if (this.minutos > 59) {
      this.horas++;
      this.anexarHoras.innerHTML = "0" + this.horas;
      this.minutos = 0;
      this.anexarMinutos.innerHTML = "0" + 0;
    }


    if (this.horas > 9) {
      this.anexarHoras.innerHTML = this.horas;
    }


  }

  checkPlatform() {
   //console.log("checking platform =>", this.plt.platforms());

    if (this.plt.is('mobileweb'))
      this.setupWebRtc();
    else if (this.plt.is('android'))
      this.getAndroidPermission();
    // this.getIosPermission();
    else if (this.plt.is('ios') || this.plt.is('ipad'))
      this.getIosPermission();
    else
      this.setupWebRtc();
  }

  getAndroidPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(result => {
     //console.log('Has permission?', result.hasPermission)
      if (result.hasPermission) {
        this.getAudioPermission();
      } else {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then(data => {
         //console.log(data)
          if (data.hasPermission) {
            this.getAudioPermission();
          }

        })
      }

    },
      err => {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then(data => {
          if (data.hasPermission) {
            this.getAudioPermission();
          }
        })
      })
  }

  getAudioPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO).then(result => {
     //console.log('Has permission?', result.hasPermission)
      if (result.hasPermission) {
        this.setupWebRtc();
      } else {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO).then(data => {
         //console.log(data)
          if (data.hasPermission) {
            this.setupWebRtc();
          }

        })
      }

    },
      err => {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO).then(data => {
          if (data.hasPermission) {
            this.setupWebRtc();
          }
        })
      })
  }

  getIosPermission() {
   //console.log("Getting permission from the ios devices =>")
    this.diagnostic.isCameraAuthorized().then(data => {
     //console.log("permission for the camera =>", data);
      if (data) {
        this.getIosAudioPermission();
      } else {
        this.diagnostic.requestCameraAuthorization().then(data => {
         //console.log("gettting camera authorization =>", data);
          this.getIosAudioPermission();
        })
      }
    })
  }

  getIosAudioPermission() {
    this.diagnostic.isMicrophoneAuthorized().then(data => {
     //console.log("permission for the microphone =>", data);
      if (data) {
        this.setupWebRtc()
      } else {
        this.diagnostic.requestMicrophoneAuthorization().then(data => {
         //console.log("getting authorization microphone =>", data);
          if (data) {
            this.setupWebRtc()
          }
        })
      }
    })
  }

  setupWebRtc() {
   //console.log("setting up webrtc");
    this.senderId = this.guid();
   //console.log(this.senderId);

    var channelName = "/webrtc";
    /* this.channel = this.afDb.list(channelName);
    this.database = this.afDb.database.ref(channelName);
    this.database.on("child_added", this.readMessage.bind(this)); */

    try {
      this.pc = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" }
        ]
      }, { optional: [] });
      this.pcRemote = new RTCPeerConnection(null);
    } catch (error) {
     //console.log(error);
      this.pc = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" }
        ]
      }, { optional: [] });
      this.pcRemote = new RTCPeerConnection(null);
    }


    this.pc.onicecandidate = event => {
     //console.log(event.candidate);

      event.candidate ? this.sendMessage(this.senderId, JSON.stringify({ ice: event.candidate })) : console.log("Sent All Ice");
    }

    this.pc.onremovestream = event => {
     //console.log('Stream Ended');  
    }

    this.pc.ontrack = event => {
     //console.log("ontrack");

      (this.remote.nativeElement.srcObject = event.streams[0]);
    }

    if (this.plt.is('ios') || this.plt.is('ipad'))
      this.showIos();
    else
      this.showMe();
  }

  onTrack(event) {
   //console.log("-----------------");
   //console.log(event);

    (this.remote.nativeElement.srcObject = event.streams[0]); // use ontrack
    if (this.plt.is('ios') || this.plt.is('ipad'))
      this.showIos();
    else
      this.showMe();
  }

  sendMessage(senderId, data) {
    /* var msg = this.channel.push({ sender: senderId, message: data });
     *///msg.remove();
  }

  readMessage(data) {
   //console.log(data.val());
    if (!data) return;
    try {
      var msg = JSON.parse(data.val().message);
      let personalData = data.val().personalData;
      var sender = data.val().sender;
      if (sender != this.senderId) {
        if (msg.ice != undefined && this.pc != null) {
          this.pc.addIceCandidate(new RTCIceCandidate(msg.ice));
        } else if (msg.sdp.type == "offer") {
          this.callActive = true;
          this.pc.setRemoteDescription(new RTCSessionDescription(msg.sdp))
            .then(() => this.pc.createAnswer())
            .then(answer => this.pc.setLocalDescription(answer))
            .then(() => this.sendMessage(this.senderId, JSON.stringify({ sdp: this.pc.localDescription })));
        } else if (msg.sdp.type == "answer") {
          this.callActive = true;
          this.pc.setRemoteDescription(new RTCSessionDescription(msg.sdp));
        }
      }
    } catch (error) {
     //console.log(error);
    }
  }

  showMe() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(stream => (this.me.nativeElement.srcObject = stream))
      .then(stream => {
        this.pc.addStream(stream);
        this.localStream = stream;
      });
  }


  showIos() {
    this.me.nativeElement.setAttribute('autoplay', 'autoplay');
    this.me.nativeElement.setAttribute('playsinline', 'playsinline');
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
      // Note: Use navigator.mediaDevices.enumerateDevices() Promise to get deviceIds
      /*
      video: {
        // Test Back Camera
        //deviceId: 'com.apple.avfoundation.avcapturedevice.built-in_video:0'
        //sourceId: 'com.apple.avfoundation.avcapturedevice.built-in_video:0'
        deviceId: {
          exact: 'com.apple.avfoundation.avcapturedevice.built-in_video:0'
        }
        // Test FrameRate
        frameRate:{ min: 15.0, max: 30.0 } // Note: Back camera may only support max 30 fps
      }, 
      audio: {
        deviceId: {
          exact: 'Built-In Microphone'
        }
      }*/
    }).then((stream) => {

     //console.log('getUserMedia.stream', stream);
     //console.log('getUserMedia.stream.getTracks', stream.getTracks());

      // Note: Expose for debug
      let localStream = stream;

      // Attach local stream to video element
      this.me.nativeElement.srcObject = localStream;
      this.pc.addStream(stream);
      this.localStream = stream;

    }).catch(function (err) {
     //console.log('getUserMedia.error', err, err.stack);
    });
    // }
  }

  showRemote() {
    try {
      this.pc.createOffer()
        .then(offer => this.pc.setLocalDescription(offer))
        .then(() => {
          this.sendMessage(this.senderId, JSON.stringify({ sdp: this.pc.localDescription }));
          this.callActive = true;
        });
    } catch (error) {
      this.setupWebRtc();
     //console.log(error);
    }
  }

  hangup() {
    this.pc.close();
    let tracks = this.localStream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].stop();
    }
    this.callActive = false;
  }

  guid() {
    return (this.s4() + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + "-" + this.s4() + this.s4() + this.s4());
  }
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  public ngOnDestroy() {
    this.pc.close();
    let tracks = this.localStream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].stop();
    }
    this.callActive = false;
  }

  hasGetUserMedia() {
    const nav: any = navigator;
   //console.log(nav.webkitGetUserMedia);
    return !!(nav.webkitGetUserMedia || nav.mozGetUserMedia || nav.msGetUserMedia);
  }

  ////LOGICA DE VISTA

  

  async reportar(ev) {
    this.loadingService.show();

    let sqlCountries: string = `SELECT id as value, descripcion as label, url FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 46`;
    this.sqlGenericService.excecuteQueryStringReference(sqlCountries, "getReportType").subscribe(async (response: any) => {
      let tipoReporte = response.parameters;

      let sql: string = `SELECT u.* FROM usuario_reunion ur
      INNER JOIN usuario u
      ON (u.id = ur.id_usuario)
      INNER JOIN reunion r
      ON (r.id = ur.id_reunion) WHERE ur.id_usuario != ${this.user.id} AND r.room = '${this.room}'`;
     //console.log(sql);
      
      this.sqlGenericService.excecuteQueryStringReference(sql, "getUsers").subscribe(async (response: any) => {
        //this.loadingService.hide();
        let users = response.parameters;

        this.loadingService.hide();
        let popoverRef = await this.popoverController.create({
          component: OpcionesReporteComponent,
          componentProps: { tipoReporte, users},
          //cssClass: 'popover-class',
          event: ev,
          //translucent: true
        });
        await popoverRef.present();

        const { role } = await popoverRef.onDidDismiss();

      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        //no notificar error
      });




    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      //no notificar error
    });
  }


  /////////JITSI
  handleClose = () => {
   //console.log("handleClose");
  }

  handleParticipantLeft = async (participant) => {
   //console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant) => {
   //console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant) => {
   //console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  }

  handleVideoConferenceLeft = () => {
   //console.log("handleVideoConferenceLeft");
    //this.router.navigate(['/thank-you']);
  }

  handleMuteStatus = (audio) => {
   //console.log("handleMuteStatus", audio); // { muted: true }
  }

  handleVideoStatus = (video) => {
   //console.log("handleVideoStatus", video); // { muted: true }
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500)
    });
  }

  executeCommand(command: string) {
    this.api.executeCommand(command);;
    if (command == 'hangup') {
      //this.router.navigate(['/thank-you']);
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }
}
