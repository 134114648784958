import { UtilService } from "src/app/services/util.service";
import { LocalStorageEncryptService } from "./../../../services/local-storage-encrypt.service";
import { SqlGenericService } from "./../../../services/sqlGenericService";
import { LoaderService } from "./../../../services/loading-service";
import { Subscription } from "rxjs";
import { EventService } from "./../../../services/event.service";
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import {
  Platform,
  ModalController,
  AlertController,
  PopoverController,
} from "@ionic/angular";

import { Router, ActivatedRoute, Params } from "@angular/router";

import { UserModel } from "../shared/models/user-model";
import {
  OpenViduLayout,
  OpenViduLayoutOptions,
} from "../shared/layout/openvidu-layout";
import {
  OpenVidu,
  Session,
  Stream,
  StreamEvent,
  Publisher,
  SignalOptions,
  StreamManagerEvent,
} from "openvidu-browser";
import { OpenViduService } from "../shared/services/openvidu.service";

import {
  trigger,
  keyframes,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { ChatComponent } from "../shared/components/chat/chat.component";

import { StreamComponent } from "../shared/components/stream/stream.component";
import { SettingUpModalComponent } from "../shared/components/setting-up-modal/setting-up-modal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { idEmpresa } from "src/environments/environment.prod";
import { OpcionesReporteComponent } from "../../opciones_reporte/opciones_reporte.component";
declare var cordova;

@Component({
  selector: "app-video-room",
  templateUrl: "./video-room.page.html",
  styleUrls: ["./video-room.page.scss"],
  animations: [
    trigger("slideLeftRight", [
      state(
        "in",
        style({
          transform: "translateX(0px)",
        })
      ),
      state(
        "out",
        style({
          transform: "translateX(100px)",
        })
      ),
      transition(
        "in => out",
        animate(
          "200ms",
          keyframes([
            style({ transform: "translateX(100px)", display: "none" }),
          ])
        )
      ),
      transition(
        "out => in",
        animate("200ms", keyframes([style({ transform: "translateX(0px)" })]))
      ),
    ]),
    trigger("slideLeftRightChat", [
      state(
        "in",
        style({
          transform: "translateX(0px)",
        })
      ),
      state(
        "out",
        style({
          transform: "translateX(100px)",
        })
      ),
      transition(
        "in => out",
        animate(
          "200ms",
          keyframes([
            style({ transform: "translateX(100px)", display: "none" }),
          ])
        )
      ),
      transition(
        "out => in",
        animate("200ms", keyframes([style({ transform: "translateX(0px)" })]))
      ),
    ]),
    trigger("slideTopBottom", [
      state(
        "in",
        style({
          transform: "translateY(0px)",
        })
      ),
      state(
        "out",
        style({
          transform: "translateY(100px)",
        })
      ),
      transition(
        "in => out",
        animate(
          "200ms",
          keyframes([
            style({ transform: "translateY(100px)", display: "none" }),
          ])
        )
      ),
      transition(
        "out => in",
        animate("200ms", keyframes([style({ transform: "translateY(0px)" })]))
      ),
    ]),
  ],
})
export class VideoRoomPage implements OnInit, OnDestroy, AfterViewInit {
  // Constants
  ANDROID_PERMISSIONS = [
    "android.permission.CAMERA",
    "android.permission.RECORD_AUDIO",
    "android.permission.MODIFY_AUDIO_SETTINGS",
  ];
  BIG_ELEMENT_CLASS = "OV_big";

  buttonsVisibility = "in";
  chatNotification = "in";
  cameraBtnColor = "light";
  camBtnColor = "light";
  camBtnIcon = "videocam";
  micBtnColor = "light";
  micBtnIcon = "mic";
  chatBtnColor = "light";
  bigElement: HTMLElement;
  messageReceived = false;
  messageList: any[] = [];
  modalIsPresented = false;
  setUpModalIsPresented = true;
  videoDevices: any[] = [];
  public touch: boolean = false;

  public camaraActiva: boolean = true;

  OV: OpenVidu;
  @ViewChild("mainStream", { static: false }) mainStream: ElementRef;
  session: Session;
  openviduLayout: OpenViduLayout;
  openviduLayoutOptions: OpenViduLayoutOptions;
  mySessionId: string;
  myUserName: string;
  localUser: UserModel;
  remoteUsers: UserModel[];
  resizeTimeout;

  @ViewChildren("streamComponentRemotes")
  streamComponentRemotes: QueryList<StreamComponent>;
  @ViewChild("streamComponentLocal", { static: false })
  streamComponentLocal: StreamComponent;

  public sus: Subscription = null;
  public divOverlay: any;
  public fije: any;

  public user: any = null;

  public milissegundos: any;
  public segundos: any;
  public minutos: any;
  public horas: any;
  public anexarHoras: any;
  public anexarSegundos: any;
  public anexarMinutos: any;
  public intervalo: any = null;

  constructor(
    public platform: Platform,
    private router: Router,
    private route: ActivatedRoute,
    private openViduSrv: OpenViduService,
    public mdlCtrl: ModalController,
    public alertController: AlertController,
    private eventService: EventService,
    private loadingService: LoaderService,
    private sqlGenericService: SqlGenericService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private popoverController: PopoverController,
    private utilService: UtilService
  ) {
    this.user = this.localStorageEncryptService.getFromLocalStorage(
      "userSessionConnectandem"
    );
  }

  ngAfterViewInit(): void {
    // declarar variável horas e atribuir ligação ao tempo definido em html
    this.horas = 0;

    // declarar variável minutos e atribuir ligação ao tempo definido em html
    this.minutos = 0;

    // declarar variável segundos e atribuir ligação ao tempo definido em html
    this.segundos = 0;

    // declarar variável milissegundos e atribuir ligação ao tempo defindo em html
    this.milissegundos = 0;

    // declarar variável para anexar horas e atribuir ligação ao elemento id definido em html
    this.anexarHoras = document.getElementById("horas");

    //console.log(this.anexarHoras);

    // declarar variável para anexar minutos e atribuir ligação ao elemento id definido em html
    this.anexarMinutos = document.getElementById("minutos");

    // declarar variável para anexar segundos e atribuir ligação ao elemento id definido em html
    this.anexarSegundos = document.getElementById("segundos");

    /* let idf:any = document.getElementById("video-room-content");
        idf.addEventListener("click",(evt:any)=>{
            this.toggleButtonsOrEnlargeStream(evt);
        }); */
  }

  // definir como vai ser a contagem
  duracaoContagem() {
    // começar contagem dos milissegundos
    this.milissegundos++;

    // começar contagem dos segundos
    // se os milissegundos forem superiores a 99
    if (this.milissegundos > 99) {
      this.segundos++;
      this.anexarSegundos.innerHTML = "0" + this.segundos;
      this.milissegundos = 0;
    }

    if (this.segundos > 9) {
      this.anexarSegundos.innerHTML = this.segundos;
    }

    // começar contagem dos minutos
    // se os segundos forem superiores a 59
    if (this.segundos > 59) {
      this.minutos++;
      this.anexarMinutos.innerHTML = "0" + this.minutos;
      this.segundos = 0;
      this.anexarSegundos.innerHTML = "0" + 0;
    }

    if (this.minutos > 9) {
      this.anexarMinutos.innerHTML = this.minutos;
    }

    // começar contagem das horas
    // se os minutos forem superiores a 59
    if (this.minutos > 59) {
      this.horas++;
      this.anexarHoras.innerHTML = "0" + this.horas;
      this.minutos = 0;
      this.anexarMinutos.innerHTML = "0" + 0;
    }

    if (this.horas > 9) {
      this.anexarHoras.innerHTML = this.horas;
    }
  }

  @HostListener("window:beforeunload")
  beforeunloadHandler() {
    this.exitSession();
  }

  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    clearTimeout(this.resizeTimeout);
    this.updateLayout();
  }

  async ngOnInit() {
    // Open modal to setting up the session
    const modal = await this.mdlCtrl.create({
      component: SettingUpModalComponent,
      showBackdrop: false,
      componentProps: {},
    });

    modal.onWillDismiss().then((data: any) => {
      console.log("video devices e inputs");

      console.log(data);

      if (data.data && data.data.user) {
        this.localUser = data.data.user;
        this.videoDevices = data.data.videoDevices;
        this.setUpModalIsPresented = false;
        this.divOverlay = document.getElementById("localUser");
        this.initApp();
        this.intervalo = setInterval(() => {
          this.duracaoContagem();
        }, 10);
      } else {
        // Go back
        window.history.back();
        console.log("dismiss");

        this.eventService.send("cierraModal", true);
        //this.modalController.dismiss();
        //this.router.navigate(["reuniones"]);
      }
    });

    return await modal.present().then(() => {
      this.refreshVideos();
    });
  }

  initApp() {
    this.localUser.setType("local");
    this.checkAudioButton();
    this.checkVideoButton();
    this.remoteUsers = [];
    this.generateParticipantInfo();
    this.openviduLayout = new OpenViduLayout();
    this.openviduLayoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio:
        false /* If this is true then the aspect ratio of the video is maintained
      and minRatio and maxRatio are ignored (default false)*/,
      bigClass: "OV_big", // The class to add to elements that should be sized bigger
      bigPercentage: 0.82, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: false, // Whether to place the big one in the top left (true) or bottom right
      animate: false, // Whether you want to animate the transitions
    };
    this.openviduLayout.initLayoutContainer(
      document.getElementById("layout"),
      this.openviduLayoutOptions
    );
    if (this.platform.is("cordova") && this.platform.is("ios")) {
      setInterval(() => {
        this.updateLayout();
      }, 1000);
    }

    this.joinToSession();
    setTimeout(() => {
      this.clicked();
    }, 4000);
  }

  public dragg() {
    console.log("draggable");

    let draggable: any = document.getElementById("localUser");
    var isMouseDown,
      initX,
      initY,
      height = draggable.offsetHeight,
      width = draggable.offsetWidth;

    let self: any = this;
    draggable.addEventListener("mousedown", function (e) {
      self.touch = true;
      document.body.classList.add("no-select");
      initX = e.offsetX;
      initY = e.offsetY;
    });

    draggable.addEventListener("touchstart", function (e) {
      self.touch = true;
      document.body.classList.add("no-select");
      initX = e.offsetX;
      initY = e.offsetY;
    });

    document.addEventListener("mousemove", function (e: any) {
      if (self.touch) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height + initY) {
          cy = window.innerHeight - height;
        }
        draggable.style.left = cx + "px";
        draggable.style.top = cy + "px";
      }
    });
    document.addEventListener("touchmove", function (e: any) {
      if (self.touch) {
        var cx = e.clientX - initX,
          cy = e.clientY - initY;
        if (cx < 0) {
          cx = 0;
        }
        if (cy < 0) {
          cy = 0;
        }
        if (window.innerWidth - e.clientX + initX < width) {
          cx = window.innerWidth - width;
        }
        if (e.clientY > window.innerHeight - height + initY) {
          cy = window.innerHeight - height;
        }
        draggable.style.left = cx + "px";
        draggable.style.top = cy + "px";
      }
    });

    draggable.addEventListener("mouseup", function () {
      //console.log("up");

      self.touch = false;
      document.body.classList.remove("no-select");
    });

    draggable.addEventListener("touchend", function () {
      //console.log("up");

      self.touch = false;
      document.body.classList.remove("no-select");
    });
  }

  ngOnDestroy() {
    this.exitSession();
  }

  joinToSession() {
    this.OV = new OpenVidu();
    this.session = this.OV.initSession();
    this.subscribeToUserChanged();
    this.subscribeToStreamCreated();
    this.subscribedToStreamDestroyed();
    this.subscribedToChat();
    this.connectToSession();
  }

  exitSession() {
    if (this.session) {
      this.session
        .unpublish(<Publisher>this.localUser.getStreamManager())
        .then(() => {
          if (this.session) {
            this.session.disconnect();
          }
          this.remoteUsers = [];
          this.session = null;
          this.localUser = null;
          this.OV = null;
          this.openviduLayout = null;
          this.router.navigate([""]);
        });
    } else {
      if (this.session) {
        this.session.disconnect();
      }
      this.remoteUsers = [];
      this.session = null;
      this.localUser = null;
      this.OV = null;
      this.openviduLayout = null;
      this.router.navigate([""]);
    }
  }

  resetVideoSize() {
    const element = document.querySelector("." + this.BIG_ELEMENT_CLASS);
    if (element) {
      element.classList.remove(this.BIG_ELEMENT_CLASS);
      this.bigElement = undefined;
      this.updateLayout();
    }
  }

  checkVideoButton() {
    if (this.localUser.isVideoActive()) {
      this.camBtnIcon = "videocam";
      this.camBtnColor = "light";
    } else {
      this.camBtnIcon = "eye-off";
      this.camBtnColor = "primary";
    }
  }

  checkAudioButton() {
    if (this.localUser.isAudioActive()) {
      this.micBtnIcon = "mic";
      this.micBtnColor = "light";
    } else {
      this.micBtnIcon = "mic-off";
      this.micBtnColor = "primary";
    }
  }

  micStatusChanged(): void {
    this.localUser.setAudioActive(
      !this.localUser.getStreamManager().stream.audioActive
    );
    (<Publisher>this.localUser.getStreamManager()).publishAudio(
      this.localUser.isAudioActive()
    );
    this.checkAudioButton();
  }

  camStatusChanged(): void {
    this.localUser.setVideoActive(
      !this.localUser.getStreamManager().stream.videoActive
    );
    (<Publisher>this.localUser.getStreamManager()).publishVideo(
      this.localUser.isVideoActive()
    );
    this.checkVideoButton();
  }

  toggleCamera() {
    if (this.platform.is("cordova")) {
      if (this.videoDevices && this.videoDevices.length > 0) {
        console.log(this.videoDevices);

        let videoSource: any;
        // Select the first different device
        videoSource =
          this.videoDevices[
            this.camaraActiva ? this.videoDevices.length - 1 : 0
          ];
        console.log(videoSource);

        this.camaraActiva = !this.camaraActiva;
        //console.log('SETTING DEVICE: ', videoSource);
        this.localUser.setVideoSource(videoSource.deviceId);

        this.localUser.setIsBackCamera(!this.localUser.isBackCamera());
        this.session
          .unpublish(<Publisher>this.localUser.getStreamManager())
          .then(() => {
            let prom: any = this.platform.is("ios")
              ? this.OV.initPublisher(undefined, {
                  audioSource: undefined, // The source of audio. If undefined default microphone
                  videoSource: undefined, // The source of video. If undefined default webcam
                  publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                  publishVideo: true, // Whether you want to start publishing with your video enabled or not
                  resolution: "640x480", // The resolution of your video
                  frameRate: 30, // The frame rate of your video
                  insertMode: "APPEND", // How the video is inserted in the target element 'video-container'
                  mirror: true, // Whether to mirror your local video or not
                })
              : this.OV.initPublisher(undefined, {
                  audioSource: this.localUser.getAudioSource(),
                  videoSource: this.localUser.getVideoSource(),
                  publishAudio: this.localUser.isAudioActive(),
                  publishVideo: this.localUser.isVideoActive(),
                  mirror: !this.localUser.isBackCamera(),
                });

            /* const publisher = this.OV.initPublisher(undefined, {
                        videoSource: this.localUser.getVideoSource(),
                        publishAudio: this.localUser.isVideoActive(),
                        publishVideo: this.localUser.isVideoActive(),
                        mirror: !this.localUser.isBackCamera()
                    }); */

            this.cameraBtnColor =
              this.cameraBtnColor === "light" ? "primary" : "light";
            this.localUser.setStreamManager(null);
            setTimeout(() => {
              this.localUser.setStreamManager(prom);
              this.updateLayout();
              this.session.publish(prom).then((r) => {
                console.log("RESPUESTA");
                console.log(r);
                setTimeout(() => {
                  // this.dragg();
                }, 500);
              });
            });
          });
      }
    }
  }

  async toggleChat() {
    this.buttonsVisibility = "out";
    this.chatNotification = "out";
    const modal = await this.mdlCtrl.create({
      component: ChatComponent,
      componentProps: { user: this.localUser, messageList: this.messageList },
    });

    modal.onWillDismiss().then(() => {
      this.modalIsPresented = false;
      this.toggleButtons();
      this.updateLayout();
    });

    return await modal.present().then(() => {
      this.modalIsPresented = true;
      this.chatBtnColor = "light";
      this.messageReceived = false;
    });
  }

  public toggleButtons() {
    this.buttonsVisibility = this.buttonsVisibility === "in" ? "out" : "in";
    this.chatNotification = this.buttonsVisibility;
  }

  public toggleButtonsOrEnlargeStream(event) {
    console.log("open options");

    event.preventDefault();
    event.stopPropagation();
    const path = event.path || event.composedPath();
    const element: HTMLElement = path.filter(
      (e: HTMLElement) => e.className && e.className.includes("OT_root")
    )[0];
    if (this.bigElement && element === this.bigElement) {
      this.toggleButtons();
    } else if (this.bigElement !== element) {
      if (this.bigElement) {
        this.bigElement.classList.remove(this.BIG_ELEMENT_CLASS);
      } else {
        this.toggleButtons();
      }
      element.classList.add(this.BIG_ELEMENT_CLASS);
      this.bigElement = element;
    }
    this.updateLayout();
  }

  private generateParticipantInfo() {
    this.route.params.subscribe((params: Params) => {
      this.mySessionId = params.roomName;
      this.myUserName = "OpenVidu_User" + Math.floor(Math.random() * 100000);
    });
  }

  private deleteRemoteStream(stream: Stream): void {
    const userStream = this.remoteUsers.filter(
      (user: UserModel) => user.getStreamManager().stream === stream
    )[0];
    const index = this.remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      this.remoteUsers.splice(index, 1);
    }
  }

  private subscribeToUserChanged() {
    this.session.on("signal:userChanged", (event: any) => {
      const data = JSON.parse(event.data);
      this.remoteUsers.forEach((user: UserModel) => {
        if (user.getConnectionId() === event.from.connectionId) {
          if (data.avatar !== undefined) {
            user.setUserAvatar(data.avatar);
          }
        }
      });
    });
  }

  private subscribeToStreamCreated() {
    this.session.on("streamCreated", (event: StreamEvent) => {
      const subscriber = this.session.subscribe(event.stream, undefined);
      subscriber.on("streamPlaying", (e: StreamManagerEvent) => {
        this.updateLayout();
        (<HTMLElement>(
          subscriber.videos[0].video
        )).parentElement.classList.remove("custom-class");
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      const nickname = event.stream.connection.data.split("%")[0];
      try {
        newUser.setNickname(JSON.parse(nickname).clientData);
      } catch (err) {
        newUser.setNickname(nickname);
      }
      newUser.setType("remote");
      newUser.setUserAvatar(this.openViduSrv.getRandomAvatar());
      this.remoteUsers.push(newUser);
      this.sendSignalUserAvatar(this.localUser);
      this.buttonsVisibility = "out";
      this.chatNotification = "out";
    });
  }

  private subscribedToStreamDestroyed() {
    this.session.on("streamDestroyed", (event: StreamEvent) => {
      this.deleteRemoteStream(event.stream);
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.updateLayout();
      }, 20);
      event.preventDefault();
    });
  }

  private subscribedToChat() {
    this.session.on("signal:chat", (event: any) => {
      const data = JSON.parse(event.data);
      //console.log(data);

      const messageOwner =
        this.localUser.getConnectionId() === data.connectionId
          ? this.localUser
          : this.remoteUsers.filter(
              (user) => user.getConnectionId() === data.connectionId
            )[0];

      this.messageList.push({
        connectionId: data.connectionId,
        message: data.message,
        userAvatar: this.user.url_foto,
        other: data,
      });
      ChatComponent.prototype.scrollToBottom();

      if (!this.modalIsPresented) {
        this.chatBtnColor = "secondary";
        this.messageReceived = true;
        this.chatNotification = "in";
      }
    });
  }

  private sendSignalUserAvatar(user: UserModel): void {
    const data = {
      avatar: user.getAvatar(),
    };
    const signalOptions: SignalOptions = {
      data: JSON.stringify(data),
      type: "userChanged",
    };
    this.session.signal(signalOptions);
  }

  private connectToSession(): void {
    this.openViduSrv
      .getToken(this.mySessionId)
      .then((token) => {
        this.connect(token);
      })
      .catch((error) => {
        console.error(
          "There was an error getting the token:",
          error.code,
          error.message
        );
        this.openAlertError(error.message);
      });
  }

  private connect(token: string): void {
    this.session
      .connect(token, { clientData: this.myUserName })
      .then(() => {
        this.connectWebCam();
      })
      .catch((error) => {
        console.error(
          "There was an error connecting to the session:",
          error.code,
          error.message
        );
        this.openAlertError(error.message);
      });
  }

  private connectWebCam(): void {
    this.localUser.setNickname(this.myUserName);
    this.localUser.setConnectionId(this.session.connection.connectionId);
    this.session.publish(<Publisher>this.localUser.getStreamManager());
    this.localUser.getStreamManager().on("streamPlaying", () => {
      (<HTMLElement>(
        this.localUser.getStreamManager().videos[0].video
      )).parentElement.classList.remove("custom-class");
      this.updateLayout();
    });
    this.localUser.setUserAvatar(this.openViduSrv.getRandomAvatar());
    this.sendSignalUserAvatar(this.localUser);
    this.updateLayout();
  }

  private updateLayout() {
    this.resizeTimeout = setTimeout(() => {
      if (this.openviduLayout) {
        this.openviduLayout.updateLayout();
        //if (this.platform.is('cordova') && this.platform.is('ios')) {
        setTimeout(() => {
          if (this.streamComponentLocal) {
            this.streamComponentLocal.videoComponent.applyIosIonicVideoAttributes();
          }
          if (this.streamComponentRemotes.length > 0) {
            this.streamComponentRemotes.forEach((stream: StreamComponent) => {
              stream.videoComponent.applyIosIonicVideoAttributes();
            });
          }
        }, 250);
        //}
      }
    }, 20);
  }

  private refreshVideos() {
    if (this.platform.is("ios") && this.platform.is("cordova")) {
      let w: any = window;
      let plugins: any = w.cordova.plugins;
      //plugins.iosrtc.capacitor.registerGlobals();
      plugins.iosrtc.capacitor.refreshVideos();
      //cordova.plugins.iosrtc.capacitor.refreshVideos();
      //cordova.plugins.iosrtc.capacitor.refreshVideos();
    }
  }

  private async openAlertError(message: string) {
    const alert = await this.alertController.create({
      header: "Error occurred!",
      subHeader: "There was an error connecting to the session:",
      message: message,
      buttons: ["OK"],
    });
    await alert.present();
  }

  clicked() {
    this.divOverlay = document.getElementById("localUser");
    //console.log(this.divOverlay);

    this.divOverlay.style.position = "fixed";
    this.divOverlay.style.margin = "0";
    this.divOverlay.style.display = "flex";
    this.divOverlay.style.width = "140px";
    /*MOUSE-LISTENERS---------------------------------------------*/
    var isDown = false;
    this.divOverlay.addEventListener("mousedown", this.mouseDown, true);
    document.addEventListener("mouseup", this.mouseUp, true);
    document.addEventListener("mousemove", this.mouseMove, true);

    /*TOUCH-LISTENERS---------------------------------------------*/
    var startX = 0,
      startY = 0;
    this.divOverlay.addEventListener("touchstart", this.touchstart, true);

    this.divOverlay.addEventListener("touchmove", this.touchmove, true);

    let p: any = document.getElementById("localUser");
    p.addEventListener(
      "click",
      function init() {
        p.removeEventListener("click", init, false);
        p.className = p.className + " resizable";
        var resizer = document.createElement("div");
        resizer.className = "resizer";
        p.appendChild(resizer);
        resizer.addEventListener("mousedown", initDrag, false);
      },
      false
    );

    var startX2, startY2, startWidth, startHeight;

    function initDrag(e) {
      startX2 = e.clientX;
      startY2 = e.clientY;
      startWidth = parseInt(document.defaultView.getComputedStyle(p).width, 10);
      startHeight = parseInt(
        document.defaultView.getComputedStyle(p).height,
        10
      );
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
    }

    function doDrag(e) {
      p.style.width = startWidth + e.clientX - startX2 + "px";
      p.style.height = startHeight + e.clientY - startY2 + "px";
    }

    function stopDrag(e) {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);
    }
  }

  public isDown = false;
  mouseMove(e: any) {
    e.preventDefault();
    if (this.isDown) {
      this.divOverlay.style.left =
        this.divOverlay.offsetLeft + e.movementX + "px";
      this.divOverlay.style.top =
        this.divOverlay.offsetTop + e.movementY + "px";
    }
  }

  mouseDown() {
    this.isDown = true;
  }
  mouseUp() {
    this.isDown = false;
  }

  public startX = 0;
  public startY = 0;

  touchstart(e) {
    this.startX = e.changedTouches[0].pageX;
    this.startY = e.changedTouches[0].pageY;
    //console.log("---->");
  }

  touchmove(e) {
    let a: any = this;

    e.preventDefault();
    var deltaX = e.changedTouches[0].pageX - this.startX;
    var deltaY = e.changedTouches[0].pageY - this.startY;
    //console.log(a);

    a.style.left = a.offsetLeft + deltaX + "px";
    a.style.top = a.offsetTop + deltaY + "px";
    //reset start-position for next frame/iteration
    this.startX = e.changedTouches[0].pageX;
    this.startY = e.changedTouches[0].pageY;
  }

  async reportar(ev) {
    console.log(this.mySessionId);
    var editedText = this.utilService.replaceAll(
      this.mySessionId.toString(),
      "tandemKEY",
      ""
    ); //.slice(0, -1)

    this.loadingService.show();

    let sqlCountries: string = `SELECT id as value, descripcion as label, nombre, url FROM catalogo WHERE id_empresa = ${idEmpresa} AND id_tipo_catalogo = 46`;
    this.sqlGenericService
      .excecuteQueryStringReference(sqlCountries, "getReportType")
      .subscribe(
        async (response: any) => {
          let tipoReporte = response.parameters;
          let sql: string = `SELECT u.* FROM usuario_reunion ur
          INNER JOIN usuario u
          ON (u.id = ur.id_usuario)
          INNER JOIN reunion r
          ON (r.id = ur.id_reunion) WHERE ur.id_usuario != ${this.user.id} AND r.room = '${editedText}'`;
          console.log(sql);

          this.sqlGenericService
            .excecuteQueryStringReference(sql, "getUsers")
            .subscribe(
              async (response: any) => {
                //this.loadingService.hide();
                let users = response.parameters;

                this.loadingService.hide();
                let popoverRef = await this.popoverController.create({
                  component: OpcionesReporteComponent,
                  componentProps: { tipoReporte, users },
                  //cssClass: 'popover-class',
                  event: ev,
                  //translucent: true
                });
                await popoverRef.present();

                const { role } = await popoverRef.onDidDismiss();
              },
              (error: HttpErrorResponse) => {
                this.loadingService.hide();
                //no notificar error
              }
            );
        },
        (error: HttpErrorResponse) => {
          this.loadingService.hide();
          //no notificar error
        }
      );
  }
}
