import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loading-service';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';


// import Swiper JS
declare var Swiper;
import 'swiper/swiper-bundle.css';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-identificar',
  templateUrl: './identificar.page.html',
  styleUrls: ['./identificar.page.scss'],
})
export class IdentificarPage implements OnInit, AfterViewInit {

  public epochNumber: any = null;
  public reintentar:boolean = false;

  constructor(
    public themeService: ThemeService,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private loadingService: LoaderService,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.epochNumber = Number(this.route.snapshot.params.epoch);
    if (this.epochNumber) {
      this.loadingService.show("Verificando...");
      let sql: string = `SELECT * FROM usuario WHERE code_verificacion = '${this.epochNumber}'`;
      this.sqlGenericService.excecuteQueryStringReference(sql, "verification").subscribe((response: any) => {
        if (response.parameters.length > 0) {
          let userData: any = response.parameters[0];
          let sqlUpdate: string = `UPDATE usuario SET verificado = 1 WHERE id = ${userData.id}`;
          this.sqlGenericService.excecuteQueryStringReference(sqlUpdate, "updateUser").subscribe((response: any) => {
            this.alertService.successAlert("Bien!", "Usuario verificado, ahora puedes ingresar a ConnecTandem, Bienvenido!");
            this.loadingService.hide();
          }, (error: HttpErrorResponse) => {
            this.loadingService.hide();
            this.alertService.warnAlert("Espera!", "Usuario no identificado");
            //no notificar error
          });
        }else{
          this.loadingService.hide();
          this.reintentar = true;
          this.alertService.warnAlert("Espera!", "Usuario no identificado");
        }
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.reintentar = true;
        this.alertService.warnAlert("Espera!", "Usuario no identificado");
        //no notificar error
      });
    }
  }

  ngAfterViewInit() {

  }

  goNext() {

  }

}
