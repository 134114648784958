import { TranslateService } from '@ngx-translate/core';
import { GenericService } from './../../services/generic.service';
import { Router } from '@angular/router';
import { LoaderService } from './../../services/loading-service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { AlertService } from './../../services/alert.service';
import { EventService } from 'src/app/services/event.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Platform, NavParams } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';

import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FCMData, FCMJson, FCMNotification } from 'src/app/services/fcm.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-meeting-date',
  templateUrl: './meeting-date.page.html',
  styleUrls: ['./meeting-date.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingDatePage implements OnInit {

  @Input() modal: boolean = false;

  public userModal: any = null;

  filteredOptions: Observable<any[]>;
  opc: any;
  miControlcito = new FormControl();
  participantes: any = [];
  selectedUsuarios: any[] = [];
  dateNow: any;

  public user: any = null;

  public firstTimeSelectDate: boolean = false;
  public firstTimeSelectHour: boolean = false;
  public dataDates = {
    inicio: {
      initDate: null,
      onChange: (evt) => {
        this.dataDates.inicio.initDate = this.dataDates.inicio.initDate.split("T")[0];
        if (!this.firstTimeSelectDate) {
          this.dataDates.fin.initDate = this.dataDates.inicio.initDate.split("T")[0];
        }
        this.firstTimeSelectDate = true;

        //console.log(this.dataDates.inicio.initDate);

      },
      placeholder: "meet.date",
      type: "date",
      error: false,
    },
    inicioHora: {
      initDate: null,
      onChange: (evt) => {
        try {
          this.dataDates.inicioHora.initDate = this.dataDates.inicioHora.initDate.split("T")[1].split(".")[0].split("-")[0];
          console.log(this.dataDates.inicioHora.initDate);

          this.dataDates.inicioHora.initDate2 = `${this.dataDates.inicio.initDate} ${this.dataDates.inicioHora.initDate}`;

          if (!this.firstTimeSelectHour) {
            let moreHour = this.dataDates.inicioHora.initDate.split(":");
            console.log(`${(Number(moreHour[0]) + 1) > 23 ? '00' : (Number(moreHour[0]) + 1) < 10 ? '0'+(Number(moreHour[0]) + 1) : (Number(moreHour[0]) + 1)}:${moreHour[1]}:${moreHour[2]}`);
            
            let buildedHour = `${(Number(moreHour[0]) + 1) > 23 ? '00' : (Number(moreHour[0]) + 1) < 10 ? '0'+(Number(moreHour[0]) + 1) : (Number(moreHour[0]) + 1)}:${moreHour[1]}:${moreHour[2]}`;
            this.dataDates.finHora.initDate = buildedHour;

            this.dataDates.finHora.initDate2 = `${this.dataDates.inicio.initDate} ${this.dataDates.finHora.initDate}`;
          }

          this.firstTimeSelectHour = true;
        } catch (error) {
          this.dataDates.inicioHora.initDate2 = `${this.dataDates.inicio.initDate} ${this.dataDates.inicioHora.initDate}`;
          ////console.log(this.dataDates.inicioHora.initDate2);
        }


      },
      placeholder: "meet.hour",
      type: "hour",
      error: false,
      initDate2: ""
    },
    fin: {
      initDate: null,
      onChange: (evt) => {
        this.dataDates.fin.initDate = this.dataDates.fin.initDate.split("T")[0];
        //this.dataDates.inicio.initDate = this.dataDates.inicio.initDate.split("T")[0];
      },
      placeholder: "meet.date",
      type: "date",
      error: false,
    },
    finHora: {
      initDate: null,
      onChange: (evt) => {
        //console.log(evt);
        try {
          this.dataDates.finHora.initDate = this.dataDates.finHora.initDate.split("T")[1].split(".")[0].split("-")[0];

          this.dataDates.finHora.initDate2 = `${this.dataDates.fin.initDate} ${this.dataDates.finHora.initDate}`;
        } catch (error) {
          this.dataDates.finHora.initDate2 = `${this.dataDates.fin.initDate} ${this.dataDates.finHora.initDate}`;

        }
        console.log(this.dataDates.inicioHora.initDate2);

      },
      placeholder: "meet.hour",
      type: "hour",
      error: false,
      initDate2: ""
    }
  };

  meet = {
    date: '',
    time: '',
    title: '',
    error: false,
    users: []
  }
  constructor(
    public themeService: ThemeService,
    public platform: Platform,
    private eventService: EventService,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private loadingService: LoaderService,
    private router: Router,
    private genericService: GenericService,
    private translateService: TranslateService
  ) {

    this.user = this.localStorageEncryptService.getFromLocalStorage("userSessionConnectandem");
  }

  ngOnInit() {
    this.dateNow = new Date();
    this.getParticipantes();
    this.filteredOptions?.subscribe((res) => {
      this.opc = res;
    });
  }

  getParticipantes() {
    let sql = `SELECT * FROM usuario WHERE id != ${this.user.id}`;
    this.sqlGenericService.excecuteQueryStringReference(sql, "getUsers").subscribe((response: any) => {
      this.participantes = response.parameters;
      //console.log(this.participantes);

      this.filteredOptions = this.miControlcito.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    })
  }
  private _filter(value: string): any {
    const filterValue = value.toLowerCase();

    return this.participantes.filter((option: any) => option?.nombre.toLowerCase().includes(filterValue));
  }

  seleccion(evt) {
    this.participantes = this.arrayRemove(this.participantes, evt);

    if (this.selectedUsuarios.length > 0) {
      this.participantes.push(this.selectedUsuarios[0]);
    }
    this.selectedUsuarios = [];
    this.selectedUsuarios.push(evt);
    this.meet.users.push(this.selectedUsuarios);
    let a: any = document.getElementById('autocomplete');
    a.value = '';
    a.blur();
    this.miControlcito.setValue("");
  }

  arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  delete(evt) {
    this.selectedUsuarios = this.arrayRemove(this.selectedUsuarios, evt)
    this.participantes.push(evt)
  }

  confirm(evt) {
    //console.log(evt);
    //console.log(this.dateNow);

  }

  agendar() {
    let keys: any = Object.keys(this.dataDates);
    let errores: number = 0;
    keys.forEach(k => {
      if (!this.dataDates[k].initDate) {
        this.dataDates[k].error = true;
        errores++;
      } else {
        this.dataDates[k].error = false;
      }
    });

    if (!this.meet.title || this.meet.title.length <= 0) {
      errores++;
      this.meet.error = true;
    } else {
      this.meet.error = false;
    }

    if (errores <= 0) {
      if (this.selectedUsuarios.length > 0) {

        let use: any = null;
        this.selectedUsuarios.forEach(us => {
          use = us;
        });

        //console.log("good");
        let epoch = new Date().getTime();
        this.loadingService.show();
        let sqlCheckForce: string = `SELECT * 
        FROM usuario_reunion ur
        INNER JOIN reunion r
        ON (r.id = ur.id_reunion) WHERE
        '${this.dataDates.inicio.initDate} ${this.dataDates.inicioHora.initDate}' between r.fecha_inicio AND r.fecha_fin AND ur.id_usuario in (${this.user.id},${use.id}) AND r.id_status_reunion = '260'`;
        let sqlCheck: string = `SELECT * FROM reunion WHERE '${this.dataDates.inicio.initDate} ${this.dataDates.inicioHora.initDate}' between fecha_inicio AND fecha_fin AND id_status_reunion = '260'`;
        console.log(sqlCheckForce);

        this.sqlGenericService.excecuteQueryStringReference(sqlCheckForce, "meetAvailable").subscribe((av: any) => {
          if (av.parameters.length > 0) {
            this.loadingService.hide();
            this.alertService.warnAlert(this.translateService.instant("alerts.wait"), this.translateService.instant("meet.meet-available"));
          } else {
            let sqlReunion: string = `INSERT INTO reunion (room, id_usuario_anfitrion, fecha_inicio, fecha_fin) VALUES ('${this.meet.title}-tan-dem-${epoch}', ${this.user.id}, '${this.dataDates.inicio.initDate} ${this.dataDates.inicioHora.initDate}','${this.dataDates.fin.initDate} ${this.dataDates.finHora.initDate}')`;
            this.sqlGenericService.excecuteQueryStringReference(sqlReunion, "createMeet").subscribe((resp: any) => {
              let returnId: any = resp.parameters;

              let sqlInserts: string = `INSERT INTO usuario_reunion (id_usuario, id_reunion, confirmado) VALUES `;

              this.selectedUsuarios.forEach(us => {
                sqlInserts += `(${us.id}, ${returnId}, 0),`;
              });
              sqlInserts += `(${this.user.id}, ${returnId}, 1)`;//el usuario que la genera
              this.sqlGenericService.excecuteQueryStringReference(sqlInserts, "createMeetDetail", 1, {

              }).subscribe((respe: any) => {


                this.selectedUsuarios.forEach(element => {

                  let notification: FCMNotification = {
                    body: `El usuario ${this.user.nombre} agendo una reunión contigo`,
                    title: `Revisa tu reunión ${this.meet.title}`,
                    click_action: "FCM_PLUGIN_ACTIVITY",
                    image: null,
                    color: "#F07C1B",
                    "content-available": true
                  };

                  let dataFCM: FCMData = {
                    body: `El usuario ${this.user.nombre} agendo una reunión contigo`,
                    title: `Revisa tu reunión ${this.meet.title}`,
                    view: 2,
                    otherData: {
                      meet: `${this.meet.title}-tan-dem-${epoch}-`
                    }
                  };

                  let fcmd: FCMJson = {
                    to: `${element.token}`,
                    notification: notification,
                    data: dataFCM,
                    priority: "high"
                  };

                  this.genericService.sendPostRequest(environment.fcm, fcmd).subscribe((FCM) => {

                  });

                });

                this.router.navigate(["/"]);
                this.loadingService.hide();
                this.alertService.successAlert(
                  this.translateService.instant("alerts.success"),
                  this.translateService.instant("meet.meet"),
                  () => {
                    ////console.log("hola");
                  }
                );


              }, (err: HttpErrorResponse) => {
                this.loadingService.hide();
              });
            }, (err: HttpErrorResponse) => {
              this.loadingService.hide();
            });
          }
        }, (err: HttpErrorResponse) => {
          this.loadingService.hide();
        });


      } else {
        this.alertService.warnAlert(
          "Ooops!",
          this.translateService.instant("meet.choose-user"),
          () => {
            ////console.log("hola");
          }
        );
      }
    } else {
      this.alertService.warnAlert(
        "Ooops!",
        this.translateService.instant("alerts.required"),
        () => {
          ////console.log("hola");
        }
      );
    }
  }

}
