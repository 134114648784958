import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';


// import Swiper JS
declare var Swiper;
import 'swiper/swiper-bundle.css';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.page.html',
  styleUrls: ['./onboarding.page.scss'],
})
export class OnboardingPage implements OnInit, AfterViewInit {

  public images: any = [{
    url: "assets/imgs/onboarding/ventana.jpg",
    title: "Sigue mejorando tu idioma con ConnecTandem",
    subtitle: "La app de universitaria para practicar tu idioma estés donde estés"
  }, {
    url: "assets/imgs/onboarding/ventana.jpg",
    title: "Continue to improve your language skills with ConnecTandem",
    subtitle: "Te university app to practice your language wherever you are"
  }];

  constructor(
    public themeService: ThemeService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const swiper = new Swiper(`.swiper-slideee`, {
      spaceBetween: 10,
      slidesPerView: 1,
      touchRatio: 0.2,
      loop: false,
      slideToClickedSlide: true,
      loopedSlides: 50,
      autoplay: false,
      direction: "horizontal",
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });
  }

  goNext(){
    this.router.navigate(["login"]);
  }
}
